import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './i18n/config'
import { configure } from 'mobx'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { init } from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { Config } from './configs/Config'

configure({
  enforceActions: 'never',
})

init({
  environment: Config.sentryEnvironment,
  dsn: Config.sentryScope,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: Config.sentryRate,
})

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
