import { Button } from '@rfh/ui'
import React, { FC } from 'react'
import Dialog from './Dialog'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router'
import useOnlineOffline from '@rfh/ui/shared/hooks/useOnlineOffline'

const DeleteTransactionDialog: FC<{
  handleCloseClick: () => void
  handleDeleteClick: () => void
  handleCancelClick: () => void
  isProcessing: boolean
  id: string
}> = ({
  handleCloseClick,
  handleDeleteClick,
  handleCancelClick,
  isProcessing = false,
  id,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { offline } = useOnlineOffline()
  return (
    <Dialog
      open={true}
      closable={true}
      handleCloseClick={handleCloseClick}
      title={t('transactions:deleteOrder')}
      contentText={
        t('transactions:areYouSureYouWantToDeleteTheOrderDefinitively', {
          transactionNumber: id,
        }) as string
      }
      buttons={[
        <Button
          disabled={offline}
          isLoading={isProcessing}
          fullWidth
          variant='contained'
          onClick={handleDeleteClick}
        >
          {t('transactions:delete')}
        </Button>,
        <Button fullWidth variant='outlined' onClick={handleCancelClick}>
          {t('transactions:cancel')}
        </Button>,
      ]}
    />
  )
}
export default observer(DeleteTransactionDialog)
