import { InputDataGridColumn } from '@rfh/ui/components/Inputs/InputDataGrid'
import {
  TransactionInputFormData,
  TransactionsFormData,
} from '../../components/forms/types'
import FormStepsStore from './FormStepsStore'

export const gridColumns: InputDataGridColumn[] = [
  {
    field: 'col1',
    headerName: 'Code',
    type: 'number',
  },
  { field: 'col2', headerName: 'Stw', type: 'number' },
  { field: 'col3', headerName: 'Stpl', type: 'number' },
  { field: 'col4', headerName: 'Stk', type: 'number' },
]

export const defaultTransactionData: TransactionInputFormData = {
  rows: [
    {
      id: 1,
      col1: { value: '' },
      col2: { value: '' },
      col3: { value: '' },
      col4: { value: '' },
    },
  ],
}

export const defaultTransactionFormData: TransactionsFormData = {
  id: '',
  returning: '',
  date: new Date(),
  createdOn: new Date(),
  customerLocation: { label: '', value: '' },
  packagingLocation: { label: '', value: '' },
  note: '',
}
export const transactionFormStepsStore = new FormStepsStore<
  TransactionsFormData,
  TransactionInputFormData
>(['/transactions/open', '/transactions/closed'])
transactionFormStepsStore.initFormData({
  information: defaultTransactionFormData,
  transaction: defaultTransactionData,
})
