import { FC, memo, useEffect } from 'react'
import { Box, RadioGroup, RadioGroupProps } from '@material-ui/core'
import { RfhRadio } from '@rfh/ui'
import { transactieTypeMapper } from '../../services/mappers'
import { useTranslation } from 'react-i18next'
import { CollectingType, Department, ReturningType } from '../../services/api/types'
import produce from 'immer'
import { toJS } from 'mobx'
import { ControllerSelectProps } from './types'

const TypesRadioGroup: FC<
  RadioGroupProps & {
    branch: Department
    variant: 'collecting' | 'returning'
    transactionTypes: (CollectingType | ReturningType)[]
    formStepsStore: ControllerSelectProps['formStepsStore']
  }
> = props => {
  const {
    value,
    branch,
    variant,
    transactionTypes,
    defaultValue,
    formStepsStore,
    ...rest
  } = props

  const { t } = useTranslation()

  useEffect(() => {
    if (transactionTypes.length === 1) {
      formStepsStore.setFormData(
        produce(toJS(formStepsStore.formData), draftState => {
          draftState.information.transactieType = transactionTypes[0]
        })
      )
    }
  }, [transactionTypes])

  return (
    <>
      <RadioGroup
        {...rest}
        value={value}
        onChange={(event, value1) => {
          formStepsStore.setFormData(
            produce(toJS(formStepsStore.formData), draftState => {
              draftState.information.transactieType = event.target.value
            })
          )
        }}
      >
        <Box display='flex' justifyContent='flex-start'>
          {transactionTypes
            .map(_type => transactieTypeMapper(_type, t))
            .map(({ label, value }, index) => (
              <RfhRadio key={index} value={value} label={label} />
            ))}
        </Box>
      </RadioGroup>
    </>
  )
}

export default memo(TypesRadioGroup)
