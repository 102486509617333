import {
  Balance,
  BalanceItemType,
  CollectingType,
  Department,
  FustCodeItemState,
  ReturningType,
  TransactionItemState,
  TransactionRecord,
} from '../api/types'
import { AppTFunction } from '../../i18n/config'
import { InputDataGridRows } from '@rfh/ui/components/Inputs/InputDataGrid'
import { TransactionCode } from '../../components/forms/types'
import { GridRowData } from '@material-ui/data-grid'
import { gridColumns } from '../../stores/forms/transactionFormStepsStore'
import globalStore from '../../stores/GlobalStore'
import _ from 'lodash'

export const balanceMapper = (balance: Balance) => {
  const dict = new Map<BalanceItemType, string>([
    ['CC', '/assets/images/cccontainers.jpg'],
    ['Plaat', '/assets/images/ccshelves.jpg'],
    ['Slotplaat', '/assets/images/lockplates.jpg'],
  ])
  return {
    ...balance,
    image: dict.get(balance.itemName) ? dict.get(balance.itemName) : '/unkonwn.jpg',
  }
}

export const randomizedImageMapper = () => {
  const imagesArray = [
    '/assets/images/flower-field-header.jpg',
    '/assets/images/colorful-flower-field-header.jpg',
    '/assets/images/lavendel-header.jpg',
    '/assets/images/poppy-header.jpg',
    '/assets/images/sunflowers-header.jpg',
    '/assets/images/tulips-header.jpg',
  ]
  return _.sample(imagesArray)
}

export const formEntityMap = (type: CollectingType | ReturningType) => {
  const dict = new Map<CollectingType | ReturningType, 'collecting' | 'returning'>([
    ['InnameMobiel', 'returning'],
    ['InnameVast', 'returning'],
    ['UitgifteDirect', 'collecting'],
    ['UitgifteDock', 'collecting'],
    ['UitgifteWinkel', 'collecting'],
  ])
  return dict.get(type)
}

export const transactieTypeMapper = (
  transactieType: CollectingType | ReturningType,
  t: AppTFunction
): {
  label: string
  value: CollectingType | ReturningType
} => {
  const dict = new Map<CollectingType | ReturningType, string>([
    ['UitgifteDock', t('collecting:dock')],
    ['UitgifteDirect', t('collecting:direct')],
    ['UitgifteWinkel', t('collecting:packagingShop')],
    ['InnameVast', t('returning:fixedReturning')],
    ['InnameMobiel', t('returning:mobileReturning')],
  ])
  return {
    label: dict.get(transactieType) || '',
    value: transactieType,
  }
}

export const departmentTypeMapper = (
  type: Department
): {
  label: string
  value: Department
} => {
  const dict = new Map<Department, string>([
    ['Aalsmeer', 'Aalsmeer'],
    ['Eelde', 'Eelde'],
    ['Naaldwijk', 'Naaldwijk'],
    ['Rijnsburg', 'Rijnsburg'],
  ])
  return {
    label: dict.get(type) || '',
    value: type,
  }
}
const getInputGridValue: ({
  code,
  rowId,
  rows,
}: {
  code: TransactionCode
  rowId: string
  rows: InputDataGridRows
}) => number | undefined = ({
  code,
  rowId,
  rows,
}: {
  code: TransactionCode
  rowId: string
  rows: InputDataGridRows
}): number | undefined => {
  const column = gridColumns.find(item => item.headerName === code)
  const row = rows.find(item => item.id === rowId)
  return row && column ? row[column.field].value : undefined
}

export const transactionRecordMapper: (
  row: GridRowData,
  rows: InputDataGridRows,
  matcher: {
    fustCodeItemStates?: FustCodeItemState[]
    type: ReturningType | CollectingType
  }
) => TransactionRecord = (
  row,
  rows: InputDataGridRows,
  { fustCodeItemStates = globalStore.fustCodeItemStates, type }
) => {
  const getValue = (code: TransactionCode) =>
    getInputGridValue({
      rowId: row.id,
      code,
      rows,
    })

  const possibleStates =
    fustCodeItemStates?.find(item => Number(item.code) === Number(getValue('Code')))
      ?.toegestaneItemStates || []
  const itemState =
    possibleStates.length > 1
      ? formEntityMap(type) === 'collecting'
        ? TransactionItemState.Clean
        : TransactionItemState.Dirty
      : possibleStates[0]

  return {
    fustCode: Number(getValue('Code')),
    itemState,
    slepen: 0,
    stapels: Number(getValue('Stpl')),
    stapelwagens: Number(getValue('Stw')),
    stuks: Number(getValue('Stk')),
    totaal: 0,
  }
}

export const openinghoursMapper = (
  department: string
): {
  collectNlUrl: string
  returnNlUrl: string
  collectEnUrl: string
  returnEnUrl: string
} => {
  const dictUitgifte = new Map<string, string>([
    ['Aalsmeer', '?accordion%5Baccordion-layout%5D%5B0%5D=aalsmeer-uitgifte&accordion%5Baccordion-layout%5D%5B1%5D='],
    ['Eelde', '?accordion%5Baccordion-layout%5D%5B0%5D=eelde-inname-uitgifte&accordion%5Baccordion-layout%5D%5B1%5D='],
    ['Naaldwijk', '?accordion%5Baccordion-layout%5D%5B0%5D=naaldwijk-uitgifte&accordion%5Baccordion-layout%5D%5B1%5D='],
    ['Rijnsburg', '?accordion%5Baccordion-layout%5D%5B0%5D=rijnsburg-uitgifte&accordion%5Baccordion-layout%5D%5B1%5D='],
  ])
  const dictInname = new Map<string, string>([
    ['Aalsmeer', '?accordion%5Baccordion-layout%5D%5B0%5D=aalsmeer-inname&accordion%5Baccordion-layout%5D%5B1%5D='],
    ['Eelde', '?accordion%5Baccordion-layout%5D%5B0%5D=eelde-inname-uitgifte&accordion%5Baccordion-layout%5D%5B1%5D='],
    ['Naaldwijk', '?accordion%5Baccordion-layout%5D%5B0%5D=naaldwijk-inname&accordion%5Baccordion-layout%5D%5B1%5D='],
    ['Rijnsburg', '?accordion%5Baccordion-layout%5D%5B0%5D=rijnsburg-inname&accordion%5Baccordion-layout%5D%5B1%5D='],
  ])
  const dictCollect = new Map<string, string>([
    ['Aalsmeer', '?accordion%5Baccordion-layout%5D%5B0%5D=aalsmeer-collecting&accordion%5Baccordion-layout%5D%5B1%5D='],
    ['Eelde', '?accordion%5Baccordion-layout%5D%5B0%5D=eelde-returning-collecting&accordion%5Baccordion-layout%5D%5B1%5D='],
    ['Naaldwijk', '?accordion%5Baccordion-layout%5D%5B0%5D=naaldwijk-collecting&accordion%5Baccordion-layout%5D%5B1%5D='],
    ['Rijnsburg', '?accordion%5Baccordion-layout%5D%5B0%5D=rijnsburg-collecting&accordion%5Baccordion-layout%5D%5B1%5D='],
  ])
  const dictReturn = new Map<string, string>([
    ['Aalsmeer', '?accordion%5Baccordion-layout%5D%5B0%5D=aalsmeer-returning&accordion%5Baccordion-layout%5D%5B1%5D='],
    ['Eelde', '?accordion%5Baccordion-layout%5D%5B0%5D=eelde-returning-collecting&accordion%5Baccordion-layout%5D%5B1%5D='],
    ['Naaldwijk', '?accordion%5Baccordion-layout%5D%5B0%5D=naaldwijk-returning&accordion%5Baccordion-layout%5D%5B1%5D='],
    ['Rijnsburg', '?accordion%5Baccordion-layout%5D%5B0%5D=rijnsburg-returning&accordion%5Baccordion-layout%5D%5B1%5D='],
  ])
  return {
    collectNlUrl: dictUitgifte.get(department) || '',
    returnNlUrl: dictInname.get(department) || '',
    collectEnUrl: dictCollect.get(department) || '',
    returnEnUrl: dictReturn.get(department) || '',
  }
}
function _sample(arg0: string[]) {
  throw new Error('Function not implemented.')
}
