import { FC } from 'react'
import { Box } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    orderedList: {
      padding: theme.spacing(0, 2),
      margin: 0,
      '& li': {
        marginBottom: theme.spacing(2),
      },
    },
  })
)

const AddToHomeScreenIOS: FC = (): JSX.Element => {
  const styles = useStyles()
  const { t, i18n } = useTranslation()

  return (
    <ol className={styles.orderedList}>
      <li>
        {t('general:help.HomeScreenIOS.shareIcon')}
        <Box mt={2}>
          <img src='assets/images/ShareButton.png' alt='share-button' width={'100%'} />
        </Box>
      </li>
      <li>
        <span
          dangerouslySetInnerHTML={{
            __html: t('general:help.HomeScreenIOS.selectHomeScreen', {
              addTohomeScreen:
                '<strong>' + t('general:help.addTohomeScreen') + '</strong>',
              interpolation: { escapeValue: false },
            }),
          }}
        ></span>
        <Box mt={2}>
          <img
            src={`assets/images/${i18n.language}-listAddToHomeScreen.png`}
            alt='ios-homepage'
            width={'100%'}
          />
        </Box>
      </li>
      <li>
        <span
          dangerouslySetInnerHTML={{
            __html: t('general:help.HomeScreenIOS.tapAddRightCorner', {
              addTo: '<strong>' + t('general:help.addTo') + '</strong>',
              interpolation: { escapeValue: false },
            }),
          }}
        ></span>

        <Box mt={2}>
          <img
            src={`assets/images/${i18n.language}-addToHomeDialog.png`}
            alt='ios-homepage'
            width={'100%'}
          />
        </Box>
      </li>
    </ol>
  )
}

export default AddToHomeScreenIOS
