import { Box } from '@material-ui/core'
import { CardHorizontal, RfhTypography } from '@rfh/ui'
import { translateBalanceItemType } from '../services/formatters'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { useBalances } from '../hooks/api'
import AppLoadingSplash from './AppLoadingSplash'
import ReloadButton from './ReloadButton'

const UserBalance = () => {
  const { t } = useTranslation()
  const { status, data: payload, error, refetch } = useBalances()
  // for other purpose
  if (status === 'loading') {
    return <AppLoadingSplash />
  }
  if (error) {
    return (
      <Box p={3}>
        <ReloadButton
          message={t('general:errors.noFormElements.balances')}
          handleRefetchClick={event => refetch()}
        />
      </Box>
    )
  }

  return (
    <Box m={3}>
      <>
        <Box pb={3}>
          <RfhTypography variant={'h3'}>{t('general:myStock')}</RfhTypography>
        </Box>
        {payload?.data.map(balance => (
          <CardHorizontal
            key={balance.itemName}
            content={
              <Box>
                <RfhTypography variant='h5'>
                  {translateBalanceItemType(t, balance.itemName)}
                </RfhTypography>
                <RfhTypography variant='h4'>
                  {t('general:{{value}} pieces', { value: balance.quantity })}
                </RfhTypography>
              </Box>
            }
            imgSrc={balance.image}
            height={96}
          />
        ))}
      </>
    </Box>
  )
}

export default observer(UserBalance)
