import { useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import {
  AppRoutingPath,
  getAppRoutingPathsWithTranslations,
} from '../configs/routes.config'
import UrlPattern from 'url-pattern'

const usePathNameTranslation = (): {
  translatedText: string | undefined
} => {
  const location = useLocation()
  const { t } = useTranslation()
  const routingPathsWithTranslation = getAppRoutingPathsWithTranslations(t)
  const allPathNames = Object.keys(routingPathsWithTranslation)
  const patternMatchedPath = allPathNames.find((pathName, index) => {
    const pattern = new UrlPattern(pathName)
    return pattern.match(location.pathname)
  })

  return {
    translatedText:
      patternMatchedPath &&
      routingPathsWithTranslation[patternMatchedPath as AppRoutingPath]
        ? routingPathsWithTranslation[patternMatchedPath as AppRoutingPath].appBarTitle
        : routingPathsWithTranslation[location.pathname as AppRoutingPath]
        ? routingPathsWithTranslation[location.pathname as AppRoutingPath].appBarTitle
        : t('general:appBar.notFound'),
  }
}
export default usePathNameTranslation
