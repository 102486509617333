import { Button, RfhTypography } from '@rfh/ui'
import { Box } from '@material-ui/core'
import { observer } from 'mobx-react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import AccordionFAQ from '../../components/AccordionFAQ'

const HelpPage: FC<unknown> = () => {
  const { t, i18n } = useTranslation()

  return (
    <Box bgcolor='white' pb={3}>
      <img
        src='/assets/images/login-header.png'
        alt='header intro image'
        style={{
          objectFit: 'cover',
          height: 212,
          maxHeight: 212,
          width: '100%',
        }}
      />
      <Box m={3}>
        <RfhTypography variant='h1'>{t('transactions:help')}</RfhTypography>
        <RfhTypography variant={'body-copy'}>
          {t('general:help.helpPageContent')}
        </RfhTypography>
        <Button
          variant='text--link-icon'
          onClick={() =>
            window.location.assign(
              `https://www.royalfloraholland.com/${i18n.language}/algemeen/contact`
            )
          }
        >
          {t('general:help.contactForm')}
        </Button>
        <Box mt={2}>
          <RfhTypography variant={'h4'}>
            {t('general:help.frequentlyAskedQuestions')}
          </RfhTypography>
        </Box>
      </Box>
      <AccordionFAQ />
    </Box>
  )
}
export default observer(HelpPage)
