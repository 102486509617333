import { useQuery } from 'react-query'
import ApiClient from '../../services/api/ApiClient'
import { Balance, MappedBalance } from '../../services/api/types'
import { balanceMapper } from '../../services/mappers'
import { balanceTypeFilter } from '../../services/filters'
import { QueryClient } from '../../services/api/QueryClient'
import { AxiosResponse } from 'axios'

export default function useBalances() {
  return useQuery(
    'balances',
    async () => {
      return ApiClient.get<MappedBalance[]>('/balances', {
        transformResponse: [
          function (data: string) {
            try {
              const payload: Balance[] = JSON.parse(data)
              return payload.filter(balanceTypeFilter).map(balanceMapper)
            } catch (e) {
              return data
            }
          },
        ],
      })
    },
    {
      placeholderData:
        QueryClient.getQueryData<AxiosResponse<MappedBalance[]>>('balances'),
    }
  )
}
