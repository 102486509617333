import { useMediaQuery } from '@material-ui/core'
import React, { FC } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { appRoutesConfig } from '../configs/routes.config'
import NotFound from '../views/NotFound'
import AuthenticatedRoute from './AuthenticatedRoute'
import LoginCallback from './LoginCallback'

const AppRoutes: FC<unknown> = () => {
  const matches = useMediaQuery('(min-width:600px)')
  const params = new URLSearchParams(window.location.search)
  return (
    <Switch>
      <Redirect from='/mobile-only' to='/' />
      <Route path='/callback' component={LoginCallback} />
      {Object.entries(appRoutesConfig).map(([key, subject]) =>
        subject.isAuthenticatedRoute ? (
          <AuthenticatedRoute key={key} path={key} exact={subject.exact}>
            {subject.component}
          </AuthenticatedRoute>
        ) : (
          <Route path={key} key={key} exact={subject.exact}>
            {subject.component}
          </Route>
        )
      )}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  )
}

export default AppRoutes
