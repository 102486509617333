import { Check } from '@material-ui/icons'
import { RfhPrimaryMenuProps } from '@rfh/ui'
import i18n, { AppLanguageCode, appLanguageConfig } from '../i18n/config'

const useRfhLanguageMenu = (
  onLanguageChanged?: (changedToLanguage: string) => void
): RfhPrimaryMenuProps => ({
  items: Object.keys(appLanguageConfig).map(lang => ({
    onClick: async () => {
      await i18n.changeLanguage(lang)
      onLanguageChanged && onLanguageChanged(lang)
    },
    text: appLanguageConfig[i18n.language as AppLanguageCode].languageName[
      lang as AppLanguageCode
    ],
    icon: appLanguageConfig[lang as AppLanguageCode].flagIcon,
    ...(i18n.language === lang
      ? {
          iconEnd: <Check />,
        }
      : null),
  })),
})

export default useRfhLanguageMenu
