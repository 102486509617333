import {
  Box,
  Divider,
  Drawer,
  IconButton,
  ListItemText,
  ListItem,
  List,
  ListItemIcon,
} from '@material-ui/core'
import { RfhTypography } from '@rfh/ui'
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import { observer } from 'mobx-react'
import { FC, MouseEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import withMuiBox from '../../../hocs/withMuiBox'
import {
  CollectingType,
  ReturningType,
  TransactionRecord,
  TransactionStatus,
} from 'src/services/api/types'
import moment from 'moment'
import { v4 } from 'uuid'
import { formEntityMap, transactieTypeMapper } from '../../../services/mappers'
import { appDatetimeFormat } from '../../../i18n/config'
import MoreVertIcon from '@material-ui/icons/MoreVert'

export type TransactionListItemProps = {
  id: string
  location: string
  dateTime: Date | string
  createdOn: Date
  type: CollectingType | ReturningType
  status: TransactionStatus
  note: string | undefined
  transactionRecord: TransactionRecord[]
  withMutationOptions?: boolean
  onDeleteTransactionClick?: (event: MouseEvent, id: string) => void
  onEditTransactionClick?: (event: MouseEvent, id: string, type: string) => void
}

const TransactionsListItem: FC<TransactionListItemProps> = ({
  id,
  location,
  dateTime,
  createdOn,
  type,
  status,
  note,
  transactionRecord,
  withMutationOptions = false,
  onDeleteTransactionClick,
  onEditTransactionClick,
}) => {
  const { t, i18n } = useTranslation()
  const [toggleDrawer, setToggleDrawer] = useState(false)

  const translatedType =
    formEntityMap(type) === 'collecting'
      ? t('collecting:collecting')
      : t('returning:returning')

  const isClosed = status !== TransactionStatus.New
  const handleItemClick = (event: MouseEvent) => {
    if (withMutationOptions) {
      onEditTransactionClick && onEditTransactionClick(event, id, type)
    }
  }

  return (
    <>
      <Box>
        <Box display='flex' justifyContent='space-between'>
          <Box>
            <RfhTypography variant='caption-micro'>
              #{id} - {translatedType}
            </RfhTypography>
            <RfhTypography variant='text-micro'>{location}</RfhTypography>
          </Box>
          {withMutationOptions && (
            <IconButton
              edge='end'
              onClick={(event: MouseEvent) => setToggleDrawer(!toggleDrawer)}
            >
              <MoreVertIcon />
            </IconButton>
          )}
        </Box>
        <Box
          style={withMutationOptions ? { cursor: 'pointer' } : { cursor: 'default' }}
          onClick={(event: MouseEvent) => handleItemClick(event)}
        >
          <RfhTypography variant='body-copy'>
            <strong>
              {isClosed ? t('transactions:processedDate') : t('transactions:inputDate')}
              :{' '}
            </strong>
            {moment(isClosed ? dateTime : createdOn).format(
              appDatetimeFormat[i18n.language === 'nl' ? 'nl_time' : 'en_time']
            )}
          </RfhTypography>
          <RfhTypography variant='body-copy'>
            <strong>Type: </strong>
            {`${translatedType} ${transactieTypeMapper(type, t).label}`}
          </RfhTypography>
          {transactionRecord.map(item => (
            <Box mb={1} key={v4()}>
              <RfhTypography variant='body-copy'>
                <strong>Code: </strong>
                {`${item.fustCode}, ${t('transactions:tr')} ${item.stapelwagens}, ${t(
                  'transactions:lp'
                )} ${item.stapels}, ${t('transactions:ps')} ${item.stuks}`}
              </RfhTypography>
              <RfhTypography variant='body-copy'>
                <strong>{t('transactions:total')}: </strong>
                {item.totaal}
              </RfhTypography>
              {note && isClosed && (
                <RfhTypography variant='body-copy'>
                  <strong>{t('transactions:note')}: </strong>
                  {note?.toString()}
                </RfhTypography>
              )}
            </Box>
          ))}
          <Box mt={3}>
            <Divider variant='fullWidth' />
          </Box>
        </Box>
      </Box>
      <Drawer
        anchor={'bottom'}
        open={toggleDrawer}
        onClose={(event: MouseEvent) => setToggleDrawer(false)}
      >
        <List>
          <ListItem
            button
            onClick={(event: MouseEvent): void =>
              onEditTransactionClick && onEditTransactionClick(event, id, type)
            }
          >
            <ListItemIcon>
              <CreateOutlinedIcon />
            </ListItemIcon>
            <ListItemText>{t('transactions:modify')}</ListItemText>
          </ListItem>
          <ListItem
            button
            onClick={(event: MouseEvent): void =>
              onDeleteTransactionClick && onDeleteTransactionClick(event, id)
            }
          >
            <ListItemIcon>
              <DeleteOutlinedIcon />
            </ListItemIcon>
            <ListItemText>{t('transactions:delete')}</ListItemText>
          </ListItem>
        </List>
      </Drawer>
    </>
  )
}
export default withMuiBox(observer(TransactionsListItem))
