import { observer } from 'mobx-react'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import TransactionDataGridForm from '../../../components/forms/TransactionDataGridForm'
import { useTransactionForm } from '../../../hooks'

const Collecting_2: FC<unknown> = () => {
  const { t } = useTranslation()

  const { formStepsStore } = useTransactionForm('collecting')
  return (
    <>
      <TransactionDataGridForm formStepsStore={formStepsStore} />
    </>
  )
}
export default observer(Collecting_2)
