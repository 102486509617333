import { FC, useContext, useEffect, useState } from 'react'
import { Alert, AlertTitle } from '@material-ui/lab'
import { Snackbar } from '@material-ui/core'
import { observer } from 'mobx-react'
import globalStore, { GlobalStoreContext } from '../stores/GlobalStore'

const ErrorSnackbar: FC<unknown> = () => {
  const [open, setOpen] = useState(false)
  const { globalAppError } = useContext(GlobalStoreContext)
  useEffect(() => {
    if (!open) {
      globalStore.setGlobalAppError(undefined)
    }
  }, [open])

  useEffect(() => {
    setOpen(globalAppError !== undefined)
  }, [globalAppError])

  return (
    <Snackbar
      open={open}
      autoHideDuration={20000}
      onClose={() => setOpen(false)}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert onClose={() => setOpen(false)} severity='error' icon={null}>
        <AlertTitle>{globalStore.globalAppError?.errorTitle}</AlertTitle>
        {globalStore.globalAppError?.errorSummary}
      </Alert>
    </Snackbar>
  )
}

export default observer(ErrorSnackbar)
