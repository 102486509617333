import { matchPath, useLocation } from 'react-router-dom'

export const useFormUrlAndModeChecker = () => {
  const location = useLocation()
  const matchStepsPattern = matchPath(location.pathname, {
    path: '/:type/:step/:number',
    exact: false,
    strict: false,
  })

  const matchTransaction = matchPath<{ transactionId: string }>(location.pathname, {
    path: '/:type/:step/:number/:transactionId',
    exact: true,
    strict: false,
  })
  const matchedTransactionId = matchTransaction?.params.transactionId
  return {
    matchedTransactionId,
    matchStepsPattern,
    isEditMode: matchedTransactionId !== undefined,
  }
}
