import { useEffect } from 'react'

export default function useForceHardLocationReload(): void {
  let timeout: ReturnType<typeof setTimeout>
  useEffect(() => {
    timeout = setTimeout(() => {
      window?.location?.reload()
    }, 25 * 60 * 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [])
}
