import { FC, memo } from 'react'
import { Box, RadioGroup, RadioGroupProps } from '@material-ui/core'
import { RfhRadio } from '@rfh/ui'
import { ActorFormData, ActorTypes } from './types'

const ActorTypesRadioGroup: FC<
  RadioGroupProps & {
    actorTypes: ActorTypes[]
    actorForm: ActorFormData
    handleChangeDropdown: any
  }
> = props => {
  const { value, actorTypes, handleChangeDropdown, defaultValue, actorForm, ...rest } =
    props

  return (
    <>
      <RadioGroup
        {...rest}
        value={value}
        onChange={(event, value1) => {
          actorForm.role = event.target.value

          handleChangeDropdown(event)
        }}
        defaultValue={defaultValue}
      >
        <Box>
          {actorTypes.map((at, index) => {
            return <RfhRadio key={index} value={at.value} label={at.role} />
          })}
        </Box>
      </RadioGroup>
    </>
  )
}

export default memo(ActorTypesRadioGroup)
