import { observer } from 'mobx-react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import TransactionDataGridForm from '../../../components/forms/TransactionDataGridForm'
import useTransactionForm from '../../../hooks/useTransactionForm'

const Returning_2: FC<unknown> = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { formStepsStore } = useTransactionForm('returning')
  return (
    <>
      <TransactionDataGridForm formStepsStore={formStepsStore} />
    </>
  )
}
export default observer(Returning_2)
