import { Button } from '@rfh/ui'
import React, { FC } from 'react'
import Dialog from './Dialog'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router'

const RedirectCollecting: FC<{
  handleNewOrderClick: () => void
  handleTransactionClick: () => void
  handleMainMenuClick: () => void
}> = ({ handleMainMenuClick, handleNewOrderClick, handleTransactionClick }) => {
  const { t } = useTranslation()
  const history = useHistory()
  return (
    <Dialog
      open={true}
      closable={false}
      title={t('collecting:thankYouForYourCollectingOrderWeWillGetToWorkForYou')}
      contentText={t('collecting:followYourCollectingOrderInTheOrdersScreen')}
      buttons={[
        <Button fullWidth variant='outlined' onClick={handleNewOrderClick}>
          {t('collecting:newCollectingOrder')}
        </Button>,
        <Button fullWidth variant='outlined' onClick={handleTransactionClick}>
          {t('transactions:yourTransaction')}
        </Button>,
        <Button fullWidth variant='outlined' onClick={handleMainMenuClick}>
          {t('collecting:toMainMenu')}
        </Button>,
      ]}
    />
  )
}
export default observer(RedirectCollecting)
