import {
  EditCollectingContext,
  NewCollectingContext,
} from '../stores/forms/collectingFormStepsStore'
import { useContext } from 'react'
import { useFormUrlAndModeChecker } from './useFormUrlAndModeChecker'
import { useTransactionIdToSetFormData } from './useTransactionIdToSetFormData'
import {
  EditReturningContext,
  NewReturningContext,
} from '../stores/forms/returningFormStepsStore'
import {
  CollectingFormData,
  ReturningFormData,
  TransactionInputFormData,
} from 'src/components/forms/types'
import FormStepsStore from 'src/stores/forms/FormStepsStore'

const useTransactionForm = (entity: 'collecting' | 'returning') => {
  const formUrlAndModeChecker = useFormUrlAndModeChecker()
  const { matchedTransactionId, matchStepsPattern, isEditMode } = formUrlAndModeChecker
  const returningContext = isEditMode ? EditReturningContext : NewReturningContext
  const collectingContext = isEditMode ? EditCollectingContext : NewCollectingContext

  const formStepsStore:
    | FormStepsStore<CollectingFormData, TransactionInputFormData>
    | FormStepsStore<ReturningFormData, TransactionInputFormData> = useContext(
    entity === 'collecting' ? (collectingContext as any) : (returningContext as any)
  )

  const { routingPaths, iterator } = formStepsStore

  const _index = routingPaths.findIndex(item => matchStepsPattern?.url === item)
  const skipToIndex = _index === -1 ? 0 : _index
  iterator.skipToItemByIndex(skipToIndex)

  const transactionFormData = useTransactionIdToSetFormData(
    formStepsStore,
    matchedTransactionId
  )

  return {
    ...formUrlAndModeChecker,
    formStepsStore,
    routingPaths,
    iterator,
    ...transactionFormData,
  }
}

export default useTransactionForm
