import { Box } from '@material-ui/core'
import RfhButton from '@rfh/ui/components/RfhButton/RfhButton'
import { observer } from 'mobx-react'
import { FC, SetStateAction, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'
import { useKetenverbanden } from 'src/hooks/api'
import { Ketenverband } from 'src/services/api/types'
import accountStore from 'src/stores/AccountStore'
import AppLoadingSplash from './AppLoadingSplash'
import ActorSelect from './forms/ActorSelect'
import { ActorFormData, ActorTypes } from './forms/types'

const ActorNavigation: FC<{
  menuOpen?: any
}> = ({ menuOpen }) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const history = useHistory()
  const {
    isLoading: ketenverbandenIsLoading,
    data: ketenverbandenPayload,
    error: isError,
  } = useKetenverbanden()

  const [actorForm, setActorForm] = useState<ActorFormData>()
  const actorTypes: ActorTypes[] = [
    { role: t('general:ownUse'), value: 'Eigen gebruik' },
    { role: t('general:actor'), value: 'Klant' },
  ]
  const [isClientNumberLoaded, setLoadedClientNumber] = useState<boolean>(false)

  //prefillen / filling actor form
  useEffect(() => {
    accountStore.getActor()

    // If no actor found, we need to set the current role selected as Eigen gebruik
    if (!accountStore.actor) {
      setActorForm({ ...actorForm, role: 'Eigen gebruik' } as ActorFormData)
    }
    // if actor found, set the form to the current selected actor
    if (accountStore.actor) {
      setActorForm({
        ...actorForm,
        role: accountStore.actor.role,
        customer: accountStore.actor,
      } as ActorFormData)
    }
  }, [pathname])

  //clientnumber check
  useEffect(() => {
    setLoadedClientNumber(true)
  }, [accountStore.clientNumber])

  //machtigingen check
  useEffect(() => {
    if (!isError && isClientNumberLoaded && ketenverbandenPayload?.data.length === 0) {
      //empty machtigingen, set authority check
      accountStore.setAuthorized(false)
      accountStore.setActor({
        id: accountStore?.clientNumber,
        role: 'Eigen gebruik',
        client: accountStore.user?.family_name ?? '',
      })
    }
  }, [ketenverbandenIsLoading, isClientNumberLoaded, isError])

  const ownUser: Ketenverband = {
    id: accountStore.user?.customerNumber,
    name: accountStore.user?.family_name,
    role: 'Eigen gebruik',
  }
  const handleActorChange = (event: { target: { value: SetStateAction<string> } }) => {
    setActorForm({
      ...actorForm,
      role: event.target.value,
      customer: undefined,
    } as ActorFormData)
  }

  if (!accountStore.checkIfPermitted(['lmi-fustonline-isklantfusttransactie-'])) {
    return null
  }

  if (isClientNumberLoaded && ketenverbandenIsLoading) {
    return <AppLoadingSplash />
  }
  return (
    <Box style={{ padding: 10 }}>
      <ActorSelect
        actorForm={actorForm as ActorFormData}
        defaultActorValue={accountStore.actor?.id}
        defaultActorTypeValue={accountStore.actor?.role}
        defaultTitle={t('general:actorNavigationTitle')}
        placeholder={t('general:selectActor')}
        actorTypes={actorTypes}
        actors={ketenverbandenPayload?.data.concat(ownUser) || []}
        handleActorEvent={handleActorChange}
        changedRole={actorForm?.role as string}
      />
      <RfhButton
        style={{ paddingLeft: 10 }}
        onClick={() => {
          accountStore.setActor({
            client: actorForm?.customer?.client as string,
            id: actorForm?.customer?.id as number,
            role: actorForm?.customer?.role as string,
          })
          accountStore.setAuthorized(true)
          accountStore.setClientNumber(actorForm?.customer?.id as number)
          accountStore.getClientnumber()
          history.push('/')
          if (menuOpen) {
            menuOpen(false)
          }
        }}
      >
        {t('collecting:next')}
      </RfhButton>
    </Box>
  )
}
export default observer(ActorNavigation)
