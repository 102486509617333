import { OktaAuthOptions } from '@okta/okta-auth-js'

export const scopeDomainPrefix = 'lmi-fustonline-'

// These values {#...#} are replaced in the release pipeline
// If they're not replaced then the local test values are used
// else use okta
let envValue = '#{OktaOmgeving}#'
if (envValue.startsWith('#{')) {
  envValue = 'sys'
  console.log('sys release')
}

// Testing if Library Group variables are successfully read
let testValue = '#{Test}#'
if (testValue.startsWith('#{')) {
  testValue = 'Uitlezen van Library parameters werkt niet..'
}

// Enable or disable OktaAuthentication (IE: disable for Cypress testing)
let oktaAuthentication = '#{OktaAuthentication}#'
if (oktaAuthentication.startsWith('#{')) {
  oktaAuthentication = 'on'
}
let apiHostValue = '#{ApiHost}#'
if (apiHostValue.startsWith('#{')) {
  apiHostValue = 'https://lmi-to-fustonline-api.operstaging.royalfloraholland.com'
}

const baseRedirectUriValue = '#{OktaRedirectUri}#'

let redirectUriValue = baseRedirectUriValue + '/callback'
if (redirectUriValue.startsWith('#{')) {
  redirectUriValue = 'http://localhost:3000/callback'
}

let postLogoutRedirectUriValue = baseRedirectUriValue
if (postLogoutRedirectUriValue.startsWith('#{')) {
  postLogoutRedirectUriValue = 'http://localhost:3000'
}

let clientIdValue = '#{OktaClientId}#'
if (clientIdValue.startsWith('#{')) {
  clientIdValue = '0oa571otttoJwX8md0x7' //lmi-fustonline-sys
}
let domainValue = '#{OktaDomain}#'
if (domainValue.startsWith('#{')) {
  domainValue = 'https://identity-test.royalfloraholland.com/oauth2/default'
}
let issuerValue = '#{OktaIssuer}#'
if (issuerValue.startsWith('#{')) {
  issuerValue = 'https://identity-test.royalfloraholland.com/oauth2/default'
}
let scopeValue = '#{OktaScope}#'
if (scopeValue.startsWith('#{')) {
  scopeValue = scopeDomainPrefix + 'api-'
}

let sentryEnvironment = '#{Omgeving}#'
if (sentryEnvironment.startsWith('#{')) {
  sentryEnvironment = 'sys'
}

const oktaScopes = ['openid', 'profile', 'email', scopeValue + envValue]

export type AppConfig = {
  env: 'dev' | 'sys' | 'acc' | 'prd' | string
  oktaRoute: string
  api: {
    host: string
    path: string
    version: string
  }
  oidc: OktaAuthOptions
  scopeDomainPrefix: string
  defaultRefreshInterval: number
  maxRetryDelay: number
  sentryScope: string
  sentryEnvironment: string
  sentryRate: number
}
export const Config: AppConfig = {
  env: envValue,
  oktaRoute: oktaAuthentication,
  api: {
    host: apiHostValue,
    path: '',
    version: '',
  },
  oidc: {
    clientId: clientIdValue,
    issuer: issuerValue,
    redirectUri: redirectUriValue,
    postLogoutRedirectUri: postLogoutRedirectUriValue,
    scopes: oktaScopes,
    pkce: true,
  },
  scopeDomainPrefix,
  defaultRefreshInterval: 25000,
  maxRetryDelay: 30000,
  sentryScope:
    'https://6550227ca83b4ea8bb10621e1e5a746a@o902103.ingest.sentry.io/5842595',
  sentryEnvironment,
  sentryRate: 0.5,
}

export type PrefixPermissionScope =
  | 'lmi-fustonline-isklantdenensaldi-'
  | 'lmi-fustonline-isklantfusttransactie-'
  | 'lmi-fustonline-ismedewerker-'

export const detectScopePerEnv = (
  prefix: PrefixPermissionScope,
  envValue = Config.env
) => {
  return prefix + envValue
}
