import LoadingIconText from '@rfh/ui/components/LoadingStates/LoadingIconText'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'

const AppLoadingSplash: FC<unknown> = () => {
  const { t } = useTranslation()
  return (
    <Box position={'absolute'} top={0} left={0}>
      <LoadingIconText positionCenter={true} loadingText={t('general:appIsLoading')} />
    </Box>
  )
}

export default AppLoadingSplash
