import { defaultTransactionData } from './transactionFormStepsStore'

import {
  CollectingFormData,
  TransactionInputFormData,
} from '../../components/forms/types'
import FormStepsStore from './FormStepsStore'
import { createContext } from 'react'
import { AppRoutingPath } from '../../configs/routes.config'

export const defaultCollectingFormData: CollectingFormData = {
  location: { label: '', value: '' },
  transactieType: '',
  date: new Date(),
  createdOn: new Date(),
  packagingLocation: { label: '', value: '' },
  customerLocation: { label: '', value: '' },
  note: '',
}
const routingPaths: AppRoutingPath[] = [
  '/collecting/step/1',
  '/collecting/step/2',
  '/collecting/step/3',
]
const defaultFormData = {
  information: defaultCollectingFormData,
  transaction: defaultTransactionData,
}
export const collectingFormStepsStore = new FormStepsStore<
  CollectingFormData,
  TransactionInputFormData
>(routingPaths)

collectingFormStepsStore.initFormData(defaultFormData)

export const NewCollectingContext = createContext<
  FormStepsStore<CollectingFormData, TransactionInputFormData>
>(collectingFormStepsStore)

export const editCollectingFormStepsStore = new FormStepsStore<
  CollectingFormData,
  TransactionInputFormData
>(routingPaths)

editCollectingFormStepsStore.initFormData(defaultFormData)
export const EditCollectingContext = createContext<
  FormStepsStore<CollectingFormData, TransactionInputFormData>
>(editCollectingFormStepsStore)
