import React from 'react'
import { Box, CircularProgress } from '@material-ui/core'

const RefreshingContent = () => {
  return (
    <Box mt={4}>
      <CircularProgress variant={'indeterminate'} size={24} />
    </Box>
  )
}

export default RefreshingContent
