import React, { useState } from 'react'
import { Cookiebar, OnlineOfflineAlert, RfhTheme, AppConfigInfoDisplay } from '@rfh/ui'
import { useTranslation } from 'react-i18next'
import AppBar from './components/AppBar'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { OktaAuth } from '@okta/okta-auth-js'
import { Security } from '@okta/okta-react'
import { Config } from './configs/Config'
import AppRoutes from './components/AppRoutes'
import ErrorSnackbar from './components/ErrorSnackbar'
import { QueryClientProvider } from 'react-query'
import { QueryClient } from './services/api/QueryClient'
import { ReactQueryDevtools } from 'react-query/devtools'
import { useForceHardLocationReload } from './hooks'
import UserInformation from './components/UserInformation'
import './App.css'

export const oktaAuth = new OktaAuth(Config.oidc)
const App = () => {
  const { t } = useTranslation()
  useForceHardLocationReload()

  return (
    <>
      <RfhTheme>
        <Router>
          <Route
            exact={true}
            path='/appconfig'
            render={() => <AppConfigInfoDisplay configObject={Config} />}
          />
        </Router>

        <OnlineOfflineAlert
          offlineMessage={t('general:offlineMessage')}
          onlineMessage={t('general:onlineMessage')}
        />
        <QueryClientProvider client={QueryClient}>
          <Router>
            <Security oktaAuth={oktaAuth}>
              <Cookiebar
                title={t('general:cookieStatement')}
                messageText={t('general:useCookies')}
                linkText={t('general:showMore')}
                linkHref={t('general:cookieStatementUrl')}
              />
              <AppBar />
              <UserInformation />
              <ErrorSnackbar />
              <AppRoutes />
            </Security>
          </Router>
          {process.env.NODE_ENV !== 'production' && (
            <ReactQueryDevtools initialIsOpen={false} />
          )}
        </QueryClientProvider>
      </RfhTheme>
    </>
  )
}

export default App
