import {
  CallToActionIcon,
  RfhAppBar,
  RfhAppBarItem,
  RfhPrimaryMenuProps,
} from '@rfh/ui'
import { observer } from 'mobx-react'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import usePathNameTranslation from '../hooks/usePathnameTranslation'
import useRfhLanguageMenu from '../hooks/useRfhLanguageMenu'
import { AppLanguageCode, appLanguageConfig } from '../i18n/config'
import { useOktaAuth } from '@okta/okta-react'
import accountStore from '../stores/AccountStore'
import AppLoadingSplash from './AppLoadingSplash'
import globalStore from '../stores/GlobalStore'
import { AppRoutingPath } from '../configs/routes.config'
import { captureException, setTag } from '@sentry/react'

const AppBar: FC<RouteComponentProps> = ({ history, location }) => {
  const [openLanguageMenu, setOpenLanguageMenu] = useState<boolean>(false)
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const { oktaAuth } = useOktaAuth()

  const languageMenu = useRfhLanguageMenu(() => {
    setOpenLanguageMenu(false)
  })

  const { translatedText } = usePathNameTranslation()
  const { t, i18n } = useTranslation()

  const isPermitted = accountStore.checkIfPermitted([
    'lmi-fustonline-isklantfusttransactie-',
  ])

  const publicPrimaryMenu: RfhPrimaryMenuProps = {
    items: [
      {
        id: 'opening-hours',
        text: t('transactions:openingHours'),
        onClick: () => {
          history.push('/openinghours' as AppRoutingPath)
          setMenuOpen(false)
        },
      },
      {
        id: 'help',
        text: t('transactions:help'),
        onClick: () => {
          history.push('/help' as AppRoutingPath)
          setMenuOpen(false)
        },
      },
      {
        id: 'contact',
        text: t('transactions:contact'),
        onClick: () => {
          window.location.assign(
            `https://www.royalfloraholland.com/${i18n.language}/algemeen/contact`
          )
          setMenuOpen(false)
        },
      },
      {
        id: 'change-language',
        icon: appLanguageConfig[i18n.language as AppLanguageCode].flagIcon,
        text: t('general:primaryMenu.changeLanguage'),
        isFooter: true,
        onClick: () => {
          setOpenLanguageMenu(true)
        },
      },
    ],
  }

  const rfhItemsWithActor: RfhAppBarItem[] = [
    {
      text: t('collecting:collecting'),
      onClick: () => {
        setMenuOpen(false)
        history.push('/collecting')
      },
    },
    {
      text: t('returning:returning'),
      onClick: () => {
        setMenuOpen(false)
        history.push('/returning')
      },
    },
    {
      text: t('transactions:transactions'),
      onClick: () => {
        setMenuOpen(false)
        history.push('/transactions')
      },
    },
    {
      text: t('general:ketenverbanden'),
      onClick: () => {
        setMenuOpen(false)
        history.push('/machtiging')
      },
    },
    ...publicPrimaryMenu.items.filter(item =>
      ['opening-hours', 'help', 'contact', 'change-language'].includes(
        item.id ? item.id : ''
      )
    ),
  ]

  const rfhItems: RfhAppBarItem[] = [
    {
      text: t('collecting:collecting'),
      onClick: () => {
        setMenuOpen(false)
        history.push('/collecting')
      },
    },
    {
      text: t('returning:returning'),
      onClick: () => {
        setMenuOpen(false)
        history.push('/returning')
      },
    },
    {
      text: t('transactions:transactions'),
      onClick: () => {
        setMenuOpen(false)
        history.push('/transactions')
      },
    },
    ...publicPrimaryMenu.items.filter(item =>
      ['opening-hours', 'help', 'contact', 'change-language'].includes(
        item.id ? item.id : ''
      )
    ),
  ]

  const authenticatedPrimaryMenu: RfhPrimaryMenuProps = {
    content: (
      <CallToActionIcon
        title={`${accountStore.user?.family_name as string}`}
        linkText={t('general:primaryMenu.logout')}
        onActionClick={async () => {
          try {
            accountStore.clearActor()
            accountStore.clearAuthorizer()
            accountStore.clearClientNumber()
            sessionStorage.clear()
            await oktaAuth.signOut()
            oktaAuth.tokenManager.clear()
            history.push('/')
          } catch (error) {
            globalStore.setGlobalAppError({
              errorTitle: 'oktaAuth.signOut()',
              errorSummary: JSON.stringify(error, null, 4),
            })
            setTag('errorLocation', 'Appbar signout')
            captureException(error)
          }
        }}
      />
    ),
    items: accountStore.hasAuthority ? rfhItemsWithActor : rfhItems,
  }

  if (location.pathname === '/mobile-only' || location.pathname === '/signout') {
    return null
  }

  if (!accountStore.authStateReady && !globalStore.globalAppError) {
    return <AppLoadingSplash />
  }
  return (
    <>
      <RfhAppBar
        menuOpen={menuOpen}
        onLogoClick={() => {
          history.push('/')
        }}
        onMenuOpen={() => {
          setMenuOpen(true)
        }}
        onMenuClose={() => {
          setOpenLanguageMenu(false)
          setMenuOpen(false)
        }}
        title={menuOpen ? t('general:appBar.mainMenu') : translatedText}
        primaryMenu={
          openLanguageMenu
            ? languageMenu
            : accountStore.isAuthenticated && isPermitted
            ? authenticatedPrimaryMenu
            : publicPrimaryMenu
        }
      />
    </>
  )
}

export default withRouter(observer(AppBar))
