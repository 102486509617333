import { observer } from 'mobx-react'
import { FC } from 'react'
import TransactionList from './TransactionList'
import useTransactions from '../../../hooks/api/useTransactions'
import { TransactionStatus } from '../../../services/api/types'

const Transactions_open: FC<unknown> = () => {
  const hook = useTransactions(true)
  const { data: transactionsPayload } = hook

  const transactions = transactionsPayload?.data.filter(
    item => item.status === TransactionStatus.New
  )
  return (
    <TransactionList
      {...hook}
      transactions={transactions || []}
      withMutationOptions={true}
    />
  )
}

export default observer(Transactions_open)
