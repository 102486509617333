import { useQuery } from 'react-query'
import ApiClient from '../../services/api/ApiClient'
import { MultiDepot } from '../../services/api/types'
import { AxiosResponse } from 'axios'
import { QueryClient } from '../../services/api/QueryClient'

export default function useMultiDepots(variant: 'collecting' | 'returning') {
  const apiEndpointAndKey = `depots/${variant}`
  return useQuery(
    apiEndpointAndKey,
    async () => {
      return ApiClient.get<MultiDepot[]>(apiEndpointAndKey)
    },
    {
      placeholderData:
        QueryClient.getQueryData<AxiosResponse<MultiDepot[]>>(apiEndpointAndKey),
    }
  )
}
