import moment from 'moment'
import { AppTFunction } from '../../i18n/config'
import { BalanceItemType } from '../api/types'

export const translateBalanceItemType = (t: AppTFunction, type: BalanceItemType) => {
  const dict = new Map<BalanceItemType, string>([
    ['CC', t('general:ccContainers')],
    ['Plaat', t('general:ccShelves')],
    ['Slotplaat', t('general:lockPlates')],
  ])

  return dict.get(type) ? dict.get(type) : type
}

export const convertToDateString = (date: Date): string => {
  return moment(date).format('YYYY-MM-DD')
}
