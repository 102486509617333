import { RfhTypography, Button } from '@rfh/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

const ReloadButton: FC<{
  message: string
  handleRefetchClick: (event: MouseEvent) => void
}> = ({ message, handleRefetchClick }) => {
  const { t } = useTranslation()
  return (
    <>
      <RfhTypography variant={'body-copy'}>{message}</RfhTypography>
      <Button
        variant={'text--link-icon'}
        onClick={(event: MouseEvent): void => handleRefetchClick(event)}
      >
        {t('general:errors.reload')}
      </Button>
    </>
  )
}

export default ReloadButton
