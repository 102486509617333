import { useQuery, UseQueryResult } from 'react-query'
import axios, { AxiosResponse } from 'axios'
import { QueryClient } from '../../services/api/QueryClient'
import { Ketenverband } from 'src/services/api/types'
import KetenverbandClient from 'src/services/api/KetenverbandClient'

export default function useKetenverbanden(): UseQueryResult<
  AxiosResponse<Ketenverband[]>
> {
  return useQuery(
    'ketenverband',
    async () => {
      const source = axios.CancelToken.source()
      return KetenverbandClient.get<Ketenverband[]>('/ketenverband', {
        cancelToken: source.token,
      })
    },
    {
      placeholderData:
        QueryClient.getQueryData<AxiosResponse<Ketenverband[]>>('ketenverband'),
    }
  )
}
