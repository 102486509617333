import { FC } from 'react'
import { RfhTypography, StatusIndicator } from '@rfh/ui'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { appRoutesConfig } from '../../../configs/routes.config'
import AppLoadingSplash from '../../../components/AppLoadingSplash'
import { useTransactionForm } from '../../../hooks'
import { Box } from '@material-ui/core'
import accountStore from '../../../stores/AccountStore'
import NoPermission from '../../../views/NoPermission'

const Returning: FC<unknown> = () => {
  const { t } = useTranslation()

  const {
    routingPaths,
    isLoading,
    isEditMode,
    matchedTransactionId,
    iterator,
    data: transactionsPayload,
  } = useTransactionForm('returning')

  if (
    accountStore.permittedScopes?.length &&
    !accountStore.checkIfPermitted(['lmi-fustonline-isklantfusttransactie-'])
  ) {
    return <NoPermission />
  }

  if (isEditMode && isLoading) {
    return <AppLoadingSplash />
  }

  if (
    matchedTransactionId &&
    !transactionsPayload?.data.find(
      item => item.id.toString() === matchedTransactionId.toString()
    )
  ) {
    return <Redirect to={'/not-found'} />
  }
  return (
    <>
      <StatusIndicator
        title={`${t('returning:returning')}: `}
        content={t('returning:returningForm')}
        endContent={
          <RfhTypography variant={'caption-micro'}>
            <strong>{iterator.getCurrentIndex() + 1}</strong>/{routingPaths.length}
          </RfhTypography>
        }
      />
      {matchedTransactionId && (
        <Box mx={3} my={2}>
          <RfhTypography variant={'h3'}>
            {t('transactions:youreviewingTransaction', {
              transactionId: matchedTransactionId,
            })}
          </RfhTypography>
        </Box>
      )}
      <Switch>
        {routingPaths.map(path => {
          return (
            <Route key={path} exact={appRoutesConfig[path].exact} path={path}>
              {appRoutesConfig[path].component}
            </Route>
          )
        })}
        <Route>
          <Redirect to={routingPaths[0]} />
        </Route>
      </Switch>
    </>
  )
}
export default observer(Returning)
