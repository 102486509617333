import React, { FC, useState } from 'react'
import { RfhTypography } from '@rfh/ui'
import {
  Box,
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  makeStyles,
} from '@material-ui/core'
import { CloseIcon } from '@rfh/ui/shared/floriday-icons'
import { RfhButtonProps } from '@rfh/ui/components/RfhButton/RfhButton'
import { TextFieldProps } from '@rfh/ui/components/Inputs/TextField'

const useStyles = makeStyles(theme => ({
  dialog: {
    '& .MuiPaper-rounded': {
      width: '100%',
    },
  },
  icon: {
    fontSize: theme.spacing(2),
  },
}))

export type DialogProps = {
  title: string
  closable?: boolean
  handleCloseClick?: () => void
  contentText?: string
  textField?: TextFieldProps
  buttons?: RfhButtonProps[]
  inlineButtons?: boolean
} & MuiDialogProps

const Dialog: FC<DialogProps> = props => {
  const {
    open,
    closable = true,
    handleCloseClick,
    title,
    contentText,
    textField,
    buttons,
    inlineButtons = false,
    ...rest
  } = props
  const [state, setState] = useState(open)
  const styles = useStyles()
  const handleClose = () => {
    setState(false)
  }

  return (
    <>
      <MuiDialog
        {...rest}
        open={state}
        onClose={handleClose}
        className={styles.dialog}
        disableBackdropClick={!closable}
        disableEscapeKeyDown={!closable}
      >
        {closable && (
          <Box
            pt={2}
            pr={2}
            display='flex'
            justifyContent='flex-end'
            onClick={handleCloseClick && handleCloseClick}
          >
            <CloseIcon className={styles.icon} />
          </Box>
        )}
        <Box mx={3} mb={3} mt={!closable ? 3 : 0}>
          <Box textAlign='center'>
            <RfhTypography variant='h3'>{title}</RfhTypography>
            {contentText && (
              <Box py={1}>
                <RfhTypography variant='body-copy'>{contentText}</RfhTypography>
              </Box>
            )}
          </Box>
          {textField && (
            <Box border={1} my={1}>
              {textField}
            </Box>
          )}
          {buttons && (
            <Box
              pt={1}
              textAlign='center'
              {...(inlineButtons && {
                display: 'flex',
                justifyContent: 'space-between',
              })}
            >
              {buttons.map((button, index) => (
                <Box key={index} py={1}>
                  {button}
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </MuiDialog>
    </>
  )
}

export default Dialog
