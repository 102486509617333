import { observer } from 'mobx-react'
import { FC } from 'react'
import { TransactionStatus } from '../../../services/api/types'
import TransactionList from './TransactionList'
import useTransactions from '../../../hooks/api/useTransactions'

const Transactions_closed: FC<unknown> = () => {
  const hook = useTransactions(true)
  const { data: transactionsPayload } = hook
  const today = new Date()
  const priorDate = new Date().setDate(today.getDate() - 30)

  const transactions = transactionsPayload?.data.filter(
    item =>
      item.status !== TransactionStatus.New &&
      new Date(item.datum) >= new Date(priorDate)
  )
  return (
    <TransactionList
      {...hook}
      transactions={transactions || []}
      withMutationOptions={false}
    />
  )
}

export default observer(Transactions_closed)
