import React, { FC } from 'react'
import {
  RouteComponentProps,
  RouteProps,
  useHistory,
  withRouter,
} from 'react-router-dom'
import { SecureRoute } from '@okta/okta-react'
import { observer } from 'mobx-react'
import { useOktaAuthEffect } from '../hooks/useOktaAuthEffect'

const AuthenticatedRoute: FC<RouteComponentProps & RouteProps> = ({
  children,
  ...rest
}) => {
  const history = useHistory()

  useOktaAuthEffect()

  return (
    <SecureRoute
      {...rest}
      onAuthRequired={async _oktaAuth => {
        try {
          await _oktaAuth.getUser()
          return
        } catch (e) {
          history.push('/login')
        }
      }}
    >
      {children}
    </SecureRoute>
  )
}

export default withRouter(observer(AuthenticatedRoute))
