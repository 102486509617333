import { FC } from 'react'
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useTranslation } from 'react-i18next'
import withMuiBox from '../hocs/withMuiBox'
import AddToHomeScreenIOS from './AddToHomeScreenIOS'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
    },
    header: {
      textTransform: 'none',
    },
  })
)

const AccordionFAQ: FC = (): JSX.Element => {
  const styles = useStyles()
  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='addToHomeScreen-content'
          id='addToHomeScreen-header'
          className={styles.header}
        >
          {t('general:help.addToHomeScreen')}
        </AccordionSummary>
        <AccordionDetails>
          <AddToHomeScreenIOS />
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default withMuiBox(AccordionFAQ)
