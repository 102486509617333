import { observer } from 'mobx-react'
import { FC } from 'react'
import UserBalance from '../../components/UserBalance'
import DashboardNavigation from 'src/components/DashboardNavigation'
import accountStore from '../../stores/AccountStore'
import ActorNavigation from 'src/components/ActorNavigation'

const IndexPage: FC<unknown> = () => {
  return (
    <>
      {!accountStore.actor?.role ? <ActorNavigation /> : <DashboardNavigation />}

      {accountStore.clientNumber &&
      accountStore.actor?.role !== 'Klant' &&
      accountStore.checkIfPermitted(['lmi-fustonline-isklantdenensaldi-']) ? (
        <UserBalance />
      ) : null}
    </>
  )
}
export default observer(IndexPage)
