import { Box } from '@material-ui/core'
import { RfhTypography } from '@rfh/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import FormStepsNavigation from './FormStepsNavigation'
import OpeninghoursCard from '../OpeninghoursCard'
import { AppRoutingPath } from '../../configs/routes.config'
import moment from 'moment'
import { observer } from 'mobx-react'
import { transactieTypeMapper } from '../../services/mappers'
import ArrayIterator from '../../stores/forms/ArrayIterator'
import { CollectingFormData, ReturningFormData } from './types'
import { appDatetimeFormat } from '../../i18n/config'
const InformationFormDisplay: FC<{
  variant: 'collecting' | 'returning'
  iterator: ArrayIterator<any>
  skipToNextPath: string
  information: CollectingFormData | ReturningFormData
}> = ({ variant, iterator, skipToNextPath, information }) => {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const { location, date, transactieType, note } = information

  const translatedType =
    variant === 'collecting' ? t('collecting:collecting') : t('returning:returning')

  return (
    <>
      <Box my={1}>
        <Box mb={1}>
          <RfhTypography variant='body-copy'>
            <strong>{t('transactions:location')}: </strong>
            {location.value.toString()}
          </RfhTypography>
        </Box>
        <Box mb={1}>
          <RfhTypography variant='body-copy'>
            <strong>{t('transactions:plannedDate')}: </strong>
            {moment(date as Date).format(
              appDatetimeFormat[i18n.language === 'nl' ? 'nl' : 'en']
            )}
          </RfhTypography>
        </Box>
        <Box mb={1}>
          <RfhTypography variant='body-copy'>
            <strong>{t('transactions:type')}: </strong>
            {`${translatedType} ${transactieTypeMapper(transactieType, t).label}`}
          </RfhTypography>
        </Box>
        <Box mb={1}>
          <RfhTypography variant='body-copy'>
            <strong>{t('transactions:note')}: </strong>
            {note ? note?.toString() : t('transactions:noNote')}
          </RfhTypography>
        </Box>
      </Box>
      <Box my={3}>
        <OpeninghoursCard type={variant} department={location.value.toString()} />
      </Box>
      <FormStepsNavigation
        showConfirm={false}
        enableNext={iterator.hasNext()}
        enableBack={true}
        onClickBack={() => history.push('/transactions/open' as AppRoutingPath)}
        onClickNext={() => history.push(skipToNextPath)}
      />
    </>
  )
}
export default observer(InformationFormDisplay)
