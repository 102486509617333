import { Box } from '@material-ui/core'
import React, { ComponentType } from 'react'

const withMuiBox =
  <T extends Record<string, unknown>>(Component: ComponentType<T>) =>
  (props: T) =>
    (
      <Box m={3}>
        <Component {...props} />
      </Box>
    )

export default withMuiBox
