import { FC, memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import RfhSelect, { RfhSelectProps } from '@rfh/ui/components/Inputs/RfhSelect'
import { Box } from '@material-ui/core'
import { ControllerSelectProps } from './types'
import produce from 'immer'
import { toJS } from 'mobx'
import { RfhTypography } from '@rfh/ui'
import { v4 } from 'uuid'
import { CollectingType, ReturningType } from '../../services/api/types'

const CustomerLocationSelect: FC<
  Omit<RfhSelectProps, 'items'> & {
    formStepsStore: ControllerSelectProps['formStepsStore']
    type: CollectingType | ReturningType | string
    customerLocations: string[]
  }
> = props => {
  const { formStepsStore, defaultValue, customerLocations, type, ...rest } = props

  const { t } = useTranslation()

  const items = (customerLocations || []).map(item => {
    return { value: item, label: item }
  })

  useEffect(() => {
    if (items.length === 1) {
      formStepsStore.setFormData(
        produce(toJS(formStepsStore.formData), draftState => {
          draftState.information.customerLocation = items[0]
        })
      )
    }
  }, [items])

  return items.length === 1 &&
    (type === 'UitgifteDirect' || type === 'InnameMobiel') ? (
    <Box mt={2}>
      {items.map(item => (
        <RfhTypography key={v4()} variant='title-display-h4'>
          {t('general:customerLocationPrefix') + item.label}
        </RfhTypography>
      ))}
    </Box>
  ) : items.length > 1 && (type === 'UitgifteDirect' || type === 'InnameMobiel') ? (
    <RfhSelect
      defaultValue={defaultValue}
      prefix={t('general:customerLocationPrefix')}
      isClearable
      {...rest}
      items={items}
      onClearClick={() => {
        const resetState = { label: '', value: '' }
        formStepsStore.setFormData(
          produce(toJS(formStepsStore.formData), draftState => {
            draftState.information.customerLocation = resetState
          })
        )
      }}
      onSelectItemClick={(event, item): void => {
        formStepsStore.setFormData(
          produce(toJS(formStepsStore.formData), draftState => {
            draftState.information.customerLocation = item
          })
        )
      }}
    />
  ) : null
}

export default memo(CustomerLocationSelect)
