import { FC } from 'react'
import { RfhTypography } from '@rfh/ui'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import RedirectNavigation from '../components/RedirectNavigation'
import { randomizedImageMapper } from 'src/services/mappers'

const NotFound: FC<unknown> = () => {
  const { t } = useTranslation()
  return (
    <Box bgcolor='white' pb={3}>
      <img
        src={randomizedImageMapper()}
        alt='header image no acces page'
        style={{
          objectFit: 'cover',
          height: 212,
          maxHeight: 212,
          width: '100%',
        }}
      />
      <Box m={3}>
        <RfhTypography variant='h1'>{t('general:oops')}</RfhTypography>
        <Box>
          <RfhTypography variant='h4'>{t('general:noPermission')}</RfhTypography>
          <Box mt={1}>
            <RfhTypography variant='subtitle1'>
              {t('general:notFoundSubtitle')}
            </RfhTypography>
          </Box>
          <RedirectNavigation />
        </Box>
      </Box>
    </Box>
  )
}
export default observer(NotFound)
