import { useEffect } from 'react'
import {
  CollectingFormData,
  ReturningFormData,
  TransactionInputFormData,
} from 'src/components/forms/types'
import FormStepsStore from 'src/stores/forms/FormStepsStore'
import useTransactions from './api/useTransactions'
import produce from 'immer'
import { toJS } from 'mobx'
import { useLocation } from 'react-router-dom'
import { v4 } from 'uuid'
import { Transaction, TransactionRecord } from 'src/services/api/types'
import { merge } from 'lodash'

export const useTransactionIdToSetFormData = (
  formStepsStore: FormStepsStore<
    CollectingFormData | ReturningFormData,
    TransactionInputFormData
  >,
  matchTransactionId: any
) => {
  const hook = useTransactions()
  const { data: transactionsPayload } = hook
  const location = useLocation()
  const {
    formData: {
      information,
      transaction: { rows },
    },
  } = formStepsStore

  useEffect(() => {
    const currentTransaction: Transaction =
      matchTransactionId &&
      transactionsPayload?.data.find(
        item => item.id.toString() === matchTransactionId.toString()
      )
    if (currentTransaction) {
      formStepsStore.setFormData(
        produce(toJS(formStepsStore.formData), draftState => {
          const mergedLocation = {
            id: information?.location.id || currentTransaction.locatie,
            value: information?.location.value || currentTransaction.locatie,
            label: information?.location.label || currentTransaction.locatie,
          }
          draftState.information = merge(toJS(information), {
            date: currentTransaction.datum,
            createdOn: currentTransaction.createdOn,
            packagingLocation: mergedLocation,
            location: mergedLocation,
            note: information?.note || currentTransaction.externeNotitie,
            transactieType:
              information?.transactieType || currentTransaction.transactieType,
          } as typeof information)

          draftState.transaction.rows = merge(
            toJS(rows),
            currentTransaction.fustAantallen.map((item: TransactionRecord, index) => {
              const { fustCode, stapelwagens, stapels, stuks } = item
              const localStateRow = rows[index]
              return {
                id: v4(),
                col1: { value: localStateRow?.col1.value || fustCode },
                col2: { value: localStateRow?.col2.value || stapelwagens },
                col3: { value: localStateRow?.col3.value || stapels },
                col4: { value: localStateRow?.col4.value || stuks },
              }
            })
          )
        })
      )
      formStepsStore.setEditTransactionId(matchTransactionId)
    } else {
      formStepsStore.setEditTransactionId(undefined)
    }
  }, [location.pathname, transactionsPayload])

  return hook
}
