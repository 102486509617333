import { observer } from 'mobx-react'
import { FC } from 'react'
import withMuiBox from '../../../hocs/withMuiBox'
import ReturningForm from '../../../components/forms/ReturningForm'
import { useTransactionForm } from 'src/hooks'
import InformationFormDisplay from '../../../components/forms/InformationFormDisplay'

const Returning_1: FC<unknown> = () => {
  const { isEditMode, iterator, formStepsStore } = useTransactionForm('returning')
  const { formData, skipToNextPath } = formStepsStore
  const { information } = formData

  return (
    <>
      {!isEditMode ? (
        <ReturningForm />
      ) : (
        <InformationFormDisplay
          variant={'returning'}
          iterator={iterator}
          information={information}
          skipToNextPath={skipToNextPath}
        />
      )}
    </>
  )
}

export default withMuiBox(observer(Returning_1))
