import { useQuery } from 'react-query'
import ApiClient from '../../services/api/ApiClient'
import axios, { AxiosResponse } from 'axios'
import { QueryClient } from '../../services/api/QueryClient'

export default function useCustomerLocations() {
  return useQuery(
    'customerlocations',
    async () => {
      const source = axios.CancelToken.source()
      return ApiClient.get<string[]>('/customerlocations', {
        cancelToken: source.token,
      })
    },
    {
      placeholderData:
        QueryClient.getQueryData<AxiosResponse<string[]>>('customerlocations'),
    }
  )
}
