import { Box } from '@material-ui/core'
import { Button } from '@rfh/ui'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router'
import { AppRoutingPath } from 'src/configs/routes.config'
import i18n from 'src/i18n/config'

const RedirectNavigation = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Box display='flex' flexDirection='column' alignItems='flex-start'>
      <Button
        variant='text--link-icon'
        onClick={() => {
          history.push('/' as AppRoutingPath)
        }}
      >
        {t('general:homepage')}
      </Button>
      <Button
        variant='text--link-icon'
        onClick={() => {
          history.push('/openinghours' as AppRoutingPath)
        }}
      >
        {t('transactions:openingHours')}
      </Button>
      <Button
        variant='text--link-icon'
        onClick={() => {
          history.push('/help' as AppRoutingPath)
        }}
      >
        {t('transactions:help')}
      </Button>
      <Button
        variant='text--link-icon'
        onClick={() => {
          window.location.assign(
            `https://www.royalfloraholland.com/${i18n.language}/algemeen/contact`
          )
        }}
      >
        {t('transactions:contact')}
      </Button>
    </Box>
  )
}

export default observer(RedirectNavigation)
