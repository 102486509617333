import { Button, TextField } from '@rfh/ui'
import { ChangeEvent, FC } from 'react'
import Dialog from './Dialog'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router'

const AddNoteDialog: FC<{
  note: string | undefined
  handleCancelClick: () => void
  handleCloseClick?: () => void
  handleConfirmClick: () => void
  handleChangeNote: (event: ChangeEvent<Record<string | string, any>>) => void
}> = ({
  note,
  handleChangeNote,
  handleCloseClick,
  handleCancelClick,
  handleConfirmClick,
}) => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Dialog
      closable={false}
      open={true}
      inlineButtons
      title={t('transactions:note')}
      textField={
        <TextField
          multiline
          fullWidth
          placeholder={t('transactions:addANoteHere')}
          style={{ minHeight: 100 }}
          value={note}
          inputProps={{ maxLength: 200 }}
          onChange={(event: ChangeEvent): void => {
            handleChangeNote(event)
          }}
        />
      }
      buttons={[
        <Button fullWidth variant='outlined' onClick={handleCancelClick}>
          {t('transactions:cancel')}
        </Button>,
        <Button fullWidth variant='contained' onClick={handleConfirmClick}>
          {t('transactions:save')}
        </Button>,
      ]}
    />
  )
}
export default observer(AddNoteDialog)
