import { observer } from 'mobx-react'
import { FC, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useDeleteTransactionHelper } from '../../../hooks/api'
import TransactionListItem from './TransactionListItem'
import AppLoadingSplash from '../../../components/AppLoadingSplash'
import { transactionFormStepsStore } from '../../../stores/forms/transactionFormStepsStore'
import DeleteTransactionDialog from '../../../components/dialogs/DeleteTransactionDialog'
import ConfirmDeleteDialog from '../../../components/dialogs/ConfirmDeleteDialog'
import { AppRoutingPath } from '../../../configs/routes.config'
import { Button, RfhTypography } from '@rfh/ui'
import { Box } from '@material-ui/core'
import PullToRefresh from 'react-simple-pull-to-refresh'
import { Transaction } from '../../../services/api/types'
import RefreshingContent from '../../../components/RefreshingContent'
import { UseQueryResult } from 'react-query'
import { AxiosResponse } from 'axios'
import { formEntityMap } from '../../../services/mappers'
import { EditReturningContext } from '../../../stores/forms/returningFormStepsStore'
import { EditCollectingContext } from '../../../stores/forms/collectingFormStepsStore'

export type TransactionListProps = UseQueryResult<
  AxiosResponse<Transaction[]>,
  unknown
> & {
  transactions: Transaction[]
  withMutationOptions: boolean
}

const TransactionList: FC<TransactionListProps> = ({
  isLoading,
  refetch,
  withMutationOptions,
  transactions = [],
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const returningStore = useContext(EditReturningContext)
  const collectingStore = useContext(EditCollectingContext)
  const [deleteTransaction, setDeleteTransaction] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [transactionId, setTransactionId] = useState('')
  const numberOfDeleteCount = 5
  const [splicer, setSplicer] = useState<{
    start: number
    currentDeleteCount: number
  }>({
    start: 0,
    currentDeleteCount: 5,
  })

  const { mutate: deleteTransactionById, isLoading: deleteTransactionByIdIsLoading } =
    useDeleteTransactionHelper({
      onSuccess: () => {
        setDeleteTransaction(false)
        setConfirmDelete(true)
      },
    })

  if (isLoading) {
    return <AppLoadingSplash />
  }

  if (transactions.length <= 0) {
    return (
      <Box p={3}>
        <RfhTypography variant={'h3'}>
          {t('transactions:noTransactionsFound')}
        </RfhTypography>
      </Box>
    )
  }

  return (
    <PullToRefresh
      onRefresh={() => refetch()}
      refreshingContent={<RefreshingContent />}
    >
      <>
        {transactions
          .map((transaction: Transaction) => {
            return (
              <TransactionListItem
                key={transaction.id}
                id={transaction.id}
                location={transaction.locatie}
                createdOn={transaction.createdOn}
                dateTime={transaction.datum}
                status={transaction.status}
                note={transaction.externeNotitie}
                type={transaction.transactieType}
                transactionRecord={transaction.fustAantallen}
                withMutationOptions={withMutationOptions}
                onDeleteTransactionClick={(event, id) => {
                  setDeleteTransaction(true)
                  setTransactionId(id)
                }}
                onEditTransactionClick={(event, id, type) => {
                  const editPath = formEntityMap(type)
                    ? `/${formEntityMap(type)}/step/2/${id}`
                    : '/'
                  returningStore.resetFormData()
                  collectingStore.resetFormData()
                  history.push(editPath)
                }}
              />
            )
          })
          .splice(splicer.start, splicer.currentDeleteCount)}
        {splicer.currentDeleteCount <= transactions.length && (
          <Box mb={3} display='flex' justifyContent='center'>
            <Button
              variant='block--outlined'
              onClick={() => {
                setSplicer({
                  ...splicer,
                  currentDeleteCount: splicer.currentDeleteCount + numberOfDeleteCount,
                })
              }}
              isLoading={isLoading}
            >
              {t('general:showMore')}
            </Button>
          </Box>
        )}
        {deleteTransaction ? (
          <DeleteTransactionDialog
            id={transactionId}
            isProcessing={deleteTransactionByIdIsLoading}
            handleCloseClick={() => {
              setDeleteTransaction(false)
            }}
            handleCancelClick={() => {
              setDeleteTransaction(false)
            }}
            handleDeleteClick={() => {
              deleteTransactionById({ id: transactionId })
            }}
          />
        ) : confirmDelete ? (
          <ConfirmDeleteDialog
            id={transactionId}
            handleCloseClick={() => {
              setConfirmDelete(false)
              transactionFormStepsStore.resetFormData()
              history.push('/transactions' as AppRoutingPath)
            }}
          />
        ) : null}
      </>
    </PullToRefresh>
  )
}
export default observer(TransactionList)
