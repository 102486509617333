import { useOktaAuth } from '@okta/okta-react'
import { useEffect } from 'react'
import jwt_decode from 'jwt-decode'
import accountStore from '../stores/AccountStore'
import globalStore from '../stores/GlobalStore'
import { captureException, setContext, setTag, setUser } from '@sentry/react'
import { AuthApiError } from '@okta/okta-auth-js'
import { IOktaContext } from '@okta/okta-react/bundles/types/OktaContext'

export const useOktaAuthEffect = (): IOktaContext => {
  const { oktaAuth, authState } = useOktaAuth()
  const authStateReady = !authState.isPending

  useEffect(() => {
    accountStore.setAuthStateReady(authStateReady)
    if (authStateReady && authState.isAuthenticated) {
      oktaAuth
        .getUser()
        .then(user => {
          const decoded = jwt_decode(
            authState.accessToken?.accessToken as string
          ) as any
          //user okta customernumber, later to be removed
          const { klntnr } = decoded
          const decodedKeys: string[] = Object.keys(decoded as any)
          const permittedScopes: string[] = decodedKeys.filter(
            item => decoded[item] === 'true'
          )
          const _user = {
            ...user,
            customerNumber: Number(klntnr),
          }
          accountStore.clearClientNumber()
          accountStore.setUser(_user)
          accountStore.setAuthState(authState)
          accountStore.setPermittedScopes(permittedScopes)
          accountStore.setClientNumber(klntnr)
          accountStore.getActor()
          setContext('accountStore', _user)
          const scopesJoined = permittedScopes.join(',')
          setContext('Permissions', { permittedScopes: scopesJoined })
          setContext('Authstate', authState)
          setUser({
            email: _user.email,
            company: _user.family_name,
            customerNumber: _user.customerNumber,
          })
        })
        .catch(error => {
          globalStore.setGlobalAppError({
            errorTitle: 'oktaAuth.getUser()',
            errorSummary: JSON.stringify(error, null, 4),
          })
          if (!(error instanceof AuthApiError)) {
            setTag('errorLocation', 'UseOktaAuthEffect')
            captureException(error)
          }
        })
    }
  }, [oktaAuth, authState])

  return useOktaAuth()
}
