import axios from 'axios'
import { Config } from '../../configs/Config'
import { autorun } from 'mobx'
import accountStore from '../../stores/AccountStore'
import { oktaAuth } from '../../App'
import { captureException, setTag } from '@sentry/react'

accountStore.getActor()
accountStore.getClientnumber()
accountStore.getAuthorized()

const ApiClient = axios.create({
  baseURL: Config.api.host,
})

export const getTokenFromAccountStore = (): Promise<string | undefined> => {
  return new Promise(resolve => {
    autorun(() => {
      if (accountStore.isAuthenticated) {
        resolve(accountStore.accessToken?.accessToken)
      }
    })
  })
}

ApiClient.interceptors.request.use(
  async config => {
    const { accessToken } = await oktaAuth.tokenManager.getTokens()
    if (accessToken?.accessToken) {
      config.headers.Authorization = 'Bearer ' + accessToken.accessToken
      if (accountStore.clientNumber) {
        config.params = {
          actorClientNumber: accountStore.hasAuthority ? accountStore.actor?.id : null,
          customerNumber: accountStore.user?.customerNumber,
        }
      }
    }
    return config
  },
  (
    error // Do something with request error
  ) => {
    setTag('errorLocation', 'ApiClient')
    captureException(error)
    Promise.reject(error)
  }
)

export default ApiClient
