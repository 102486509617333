import { useMutation, UseMutationResult, useQuery } from 'react-query'
import ApiClient from '../../services/api/ApiClient'
import { Transaction } from '../../services/api/types'
import axios, { AxiosResponse } from 'axios'
import { useEffect } from 'react'
import globalStore from '../../stores/GlobalStore'
import { useTranslation } from 'react-i18next'
import { QueryClient } from '../../services/api/QueryClient'
import { Config } from '../../configs/Config'
import { captureException, setTag } from '@sentry/react'

export type TransactionHelperProps = {
  onSuccess: (data: AxiosResponse<Transaction>) => void
}
export type TransactionHelperFunction<ParamType> = UseMutationResult<
  AxiosResponse<Transaction>,
  unknown,
  ParamType,
  unknown
> &
  TransactionHelperProps
export default function useTransactions(refetchWithInterval?: boolean) {
  const queryKey = 'transactions'
  const intervalOptions = {
    refetchInterval: Config.defaultRefreshInterval,
    refetchOnWindowFocus: true,
  }
  const useQueryOptions = {
    placeholderData: QueryClient.getQueryData<AxiosResponse<Transaction[]>>(queryKey),
    ...(refetchWithInterval ? intervalOptions : null),
  }
  return useQuery(
    queryKey,
    async () => {
      const source = axios.CancelToken.source()
      const orderByDateAndId = (data: Transaction[]) =>
        data.sort((a: Transaction, b: Transaction) => {
          return (
            new Date(a.datum).getTime() - new Date(b.datum).getTime() &&
            Number(b.id) - Number(a.id)
          )
        })

      return ApiClient.get<Transaction[]>('/transactions', {
        cancelToken: source.token,
        transformResponse: [
          function (data: string) {
            try {
              return JSON.parse(data)
            } catch (e) {
              return data
            }
          },
          orderByDateAndId,
        ],
      })
    },
    useQueryOptions
  )
}

const useEffectErrorTransaction = ({
  error,
  isLoading,
  data,
  onSuccess,
}: {
  error: unknown
  isLoading: boolean
  data: AxiosResponse<Transaction> | undefined
  onSuccess: (data: AxiosResponse<Transaction>) => void
}) => {
  const { t } = useTranslation()
  useEffect(() => {
    if (error) {
      const parsedError: {
        message: string
        name: string
        stack: string
        config: { url: string; data: any }
      } = JSON.parse(JSON.stringify(error))

      const castingError = error as {
        response?: { data?: { errorCode?: number; errorSummary?: string } }
      }

      globalStore.setGlobalAppError({
        errorCauses: [],
        errorCode: parsedError.config.data,
        errorId: parsedError.config.url,
        errorLink: parsedError.stack,
        errorTitle: t('transactions:error'),
        errorSummary:
          castingError?.response?.data?.errorSummary || t('transactions:errorMessage'),
      })
      //we already handle status code 400, so we do not include this.
      if (!(error as Error).message.includes('400')) {
        setTag('errorLocation', 'useTransactions')
        captureException(error)
      }
    }
    if (!isLoading && data) {
      onSuccess(data)
    }
  }, [error, isLoading, data])
}

export const usePostTransaction = () => {
  return useMutation('transactions', async (transaction: Transaction) =>
    ApiClient.post<Transaction>('/transactions', {
      ...transaction,
    })
  )
}

export const usePutTransaction = () => {
  return useMutation('transactions', async (transaction: Transaction) =>
    ApiClient.put<Transaction>(
      '/transactions/' + transaction.id,
      transaction.fustAantallen
    )
  )
}

export const useDeleteTransaction = () => {
  return useMutation('transactions', async ({ id }: { id: string }) => {
    const promise = ApiClient.delete<Transaction>('/transactions/' + id)
    promise.catch(err => {
      debugger
    })
    return promise
  })
}

export const usePostTransactionHelper = ({
  onSuccess,
}: TransactionHelperProps): TransactionHelperFunction<Transaction> => {
  const hook = usePostTransaction()
  useEffectErrorTransaction({ ...hook, onSuccess })
  return {
    onSuccess,
    ...hook,
  }
}

export const usePutTransactionHelper = ({
  onSuccess,
}: TransactionHelperProps): TransactionHelperFunction<Transaction> => {
  const hook = usePutTransaction()
  useEffectErrorTransaction({ ...hook, onSuccess })
  return {
    onSuccess,
    ...hook,
  }
}

export const useDeleteTransactionHelper = ({
  onSuccess,
}: TransactionHelperProps): TransactionHelperFunction<{ id: string }> => {
  const hook = useDeleteTransaction()
  useEffectErrorTransaction({ ...hook, onSuccess })
  return {
    onSuccess,
    ...hook,
  }
}
