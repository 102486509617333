import { AppTFunction } from '../i18n/config'
import React, { ReactNode } from 'react'
import AuthenticatedIndexPage from '../views/authenticated/IndexPage'
import WelcomeB from '../views/public/LoginPage'
import Collecting from '../views/authenticated/collecting/Collecting'
import Collecting_1 from '../views/authenticated/collecting/Collecting_1'
import Collecting_2 from '../views/authenticated/collecting/Collecting_2'
import Transaction from '../views/authenticated/transactions/Transaction'
import Returning from '../views/authenticated/returning/Returning'
import Returning_1 from '../views/authenticated/returning/Returning_1'
import Returning_2 from '../views/authenticated/returning/Returning_2'
import Transactions_closed from '../views/authenticated/transactions/Transactions_closed'
import Transactions_open from '../views/authenticated/transactions/Transactions_open'
import Collecting_3 from '../views/authenticated/collecting/Collecting_3'
import Returning_3 from '../views/authenticated/returning/Returning_3'
import OpeningHours from '../views/public/OpeningHours'
import HelpPage from '../views/public/HelpPage'
import NotFound from '../views/NotFound'
import SignOutPage from '../views/public/SignOutPage'
import ActorNavigation from 'src/components/ActorNavigation'

type AppRoutesConfigOptions = {
  appBarTitle: string
  isAuthenticatedRoute: boolean
  component: JSX.Element | ReactNode
  exact: boolean
}
export const getAppRoutingPathsWithTranslations = (t: AppTFunction) => ({
  '/': {
    appBarTitle: t('general:fustOnline'),
    isAuthenticatedRoute: true,
    component: <AuthenticatedIndexPage />,
    exact: true,
  },
  '/login': {
    appBarTitle: t('general:fustOnline'),
    isAuthenticatedRoute: false,
    component: <WelcomeB />,
    exact: false,
  },
  '/welcome': {
    appBarTitle: t('general:login.welcome'),
    isAuthenticatedRoute: false,
    component: <WelcomeB />,
    exact: false,
  },
  '/welcome2': {
    appBarTitle: t('general:login.welcome'),
    isAuthenticatedRoute: false,
    component: <WelcomeB />,
    exact: false,
  },
  '/openinghours': {
    appBarTitle: t('general:fustOnline'),
    isAuthenticatedRoute: false,
    component: <OpeningHours />,
    exact: false,
  },
  '/help': {
    appBarTitle: t('general:fustOnline'),
    isAuthenticatedRoute: false,
    component: <HelpPage />,
    exact: false,
  },
  '/collecting': {
    appBarTitle: t('collecting:collecting'),
    isAuthenticatedRoute: true,
    component: <Collecting />,
    exact: false,
  } as AppRoutesConfigOptions,
  '/collecting/step/1': {
    appBarTitle: t('collecting:collecting'),
    isAuthenticatedRoute: true,
    component: <Collecting_1 />,
    exact: false,
  },
  '/collecting/step/1/:transactionId': {
    appBarTitle: t('collecting:collecting'),
    isAuthenticatedRoute: true,
    component: <Collecting_1 />,
    exact: false,
  },
  '/collecting/step/2': {
    appBarTitle: t('collecting:collecting'),
    isAuthenticatedRoute: true,
    component: <Collecting_2 />,
    exact: false,
  },
  '/collecting/step/2/:transactionId': {
    appBarTitle: t('collecting:collecting'),
    isAuthenticatedRoute: true,
    component: <Collecting_2 />,
    exact: false,
  },
  '/collecting/step/3': {
    appBarTitle: t('collecting:collecting'),
    isAuthenticatedRoute: true,
    component: <Collecting_3 />,
    exact: false,
  },
  '/collecting/step/3/:transactionId': {
    appBarTitle: t('collecting:collecting'),
    isAuthenticatedRoute: true,
    component: <Collecting_3 />,
    exact: false,
  },
  '/returning': {
    appBarTitle: t('returning:returning'),
    isAuthenticatedRoute: true,
    component: <Returning />,
    exact: false,
  } as AppRoutesConfigOptions,
  '/returning/step/1': {
    appBarTitle: t('returning:returning'),
    isAuthenticatedRoute: true,
    component: <Returning_1 />,
    exact: false,
  },
  '/returning/step/1/:transactionId': {
    appBarTitle: t('returning:returning'),
    isAuthenticatedRoute: true,
    component: <Returning_1 />,
    exact: false,
  },
  '/returning/step/2': {
    appBarTitle: t('returning:returning'),
    isAuthenticatedRoute: true,
    component: <Returning_2 />,
    exact: false,
  },
  '/returning/step/2/:transactionId': {
    appBarTitle: t('returning:returning'),
    isAuthenticatedRoute: true,
    component: <Returning_2 />,
    exact: false,
  },
  '/returning/step/3': {
    appBarTitle: t('returning:returning'),
    isAuthenticatedRoute: true,
    component: <Returning_3 />,
    exact: false,
  },
  '/returning/step/3/:transactionId': {
    appBarTitle: t('returning:returning'),
    isAuthenticatedRoute: true,
    component: <Returning_3 />,
    exact: false,
  },
  '/transactions': {
    appBarTitle: t('transactions:transactions'),
    isAuthenticatedRoute: true,
    component: <Transaction />,
    exact: false,
  } as AppRoutesConfigOptions,
  '/transactions/open': {
    appBarTitle: t('transactions:transactions'),
    isAuthenticatedRoute: true,
    component: <Transactions_open />,
    exact: true,
  },
  '/transactions/closed': {
    appBarTitle: t('transactions:transactions'),
    isAuthenticatedRoute: true,
    component: <Transactions_closed />,
    exact: true,
  },
  '/machtiging': {
    appBarTitle: t('general:ketenverbanden'),
    isAuthenticatedRoute: true,
    component: <ActorNavigation />,
    exact: true,
  },
  '/not-found': {
    appBarTitle: t('general:appBar.notFound'),
    isAuthenticatedRoute: false,
    component: <NotFound />,
    exact: true,
  },
  '/signout': {
    appBarTitle: t('general:appBar.notFound'),
    isAuthenticatedRoute: false,
    component: <SignOutPage />,
    exact: true,
  },
})
export const appRoutesConfig = getAppRoutingPathsWithTranslations(() => true)
export type AppRoutingPath = keyof typeof appRoutesConfig
