import React, { FC, memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import RfhSelect, {
  RfhSelectProps,
  SelectItemProps,
} from '@rfh/ui/components/Inputs/RfhSelect'
import { DepotSelectProps } from '../../hooks/api/useDepots'
import { Box } from '@material-ui/core'
import produce from 'immer'
import { toJS } from 'mobx'
import { ControllerSelectProps } from './types'
import { Depot } from '../../services/api/types'
import { RfhTypography } from '@rfh/ui'

const DepotSelect: FC<
  Omit<RfhSelectProps, 'items'> &
    DepotSelectProps & {
      depots: Depot[]
      formStepsStore: ControllerSelectProps['formStepsStore']
    }
> = props => {
  const { type, depots, branch, placeholder, formStepsStore, defaultValue, ...rest } =
    props
  const { t } = useTranslation()

  const items =
    depots.map(item => {
      return {
        id: item.code,
        label: item.naam,
        value: item.naam,
      } as SelectItemProps
    }) || []

  useEffect(() => {
    if (items.length === 1) {
      formStepsStore.setFormData(
        produce(toJS(formStepsStore.formData), draftState => {
          draftState.information.packagingLocation = items[0]
        })
      )
    }
  }, [items])

  return items.length === 1 ? (
    <Box mt={2}>
      {items.map(item => (
        <RfhTypography key={item.id} variant='title-display-h4'>
          {t('general:packagingLocationPrefix') + item.label}
        </RfhTypography>
      ))}
    </Box>
  ) : items.length <= 0 ? null : (
    <RfhSelect
      prefix={t('general:packagingLocationPrefix')}
      defaultValue={defaultValue}
      onClearClick={() => {
        const resetState = { label: '', value: '' }
        formStepsStore.setFormData(
          produce(toJS(formStepsStore.formData), draftState => {
            draftState.information.packagingLocation = resetState
          })
        )
      }}
      onSelectItemClick={(event, item): void => {
        formStepsStore.setFormData(
          produce(toJS(formStepsStore.formData), draftState => {
            draftState.information.packagingLocation = item
          })
        )
      }}
      isClearable
      disabled={items.length <= 0}
      placeholder={items.length <= 0 ? t('collecting:noShopFound') : placeholder}
      {...rest}
      items={items}
    />
  )
}

export default memo(DepotSelect)
