import { OktaSignOut } from '@rfh/ui'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

const SignOutPage: FC<unknown> = () => {
  const { t } = useTranslation()

  return (
    <OktaSignOut
      title={t('general:signOut.title')}
      content={t('general:signOut.content')}
      btnText={t('general:signOut.btnText')}
    />
  )
}
export default SignOutPage
