import { FC, useEffect, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { AuthApiError } from '@okta/okta-auth-js'
import withMuiBox from '../hocs/withMuiBox'
import globalStore from '../stores/GlobalStore'
import { Redirect } from 'react-router-dom'
import { observer } from 'mobx-react'
import accountStore from '../stores/AccountStore'
import { captureException, setTag } from '@sentry/react'

const LoginCallback: FC<unknown> = () => {
  const [loginError, setLoginError] = useState<AuthApiError>()
  const [shouldRedirectToLogin, setShouldRedirectToLogin] = useState<boolean>()
  const { oktaAuth, authState } = useOktaAuth()
  const authStateReady = !authState.isPending

  useEffect(() => {
    accountStore.setAuthStateReady(authStateReady)
    oktaAuth.handleLoginRedirect().catch((error: AuthApiError) => {
      setLoginError(error)
      accountStore.setAuthStateReady(true)
    })
  }, [oktaAuth])

  useEffect(() => {
    if ((authStateReady && authState.error) || (!authStateReady && loginError)) {
      const combinedError = {
        ...(authState.error as AuthApiError),
        ...loginError,
      }
      setShouldRedirectToLogin(true)
      globalStore.setGlobalAppError(combinedError)
    }
  }, [authState, loginError])

  return shouldRedirectToLogin ? <Redirect to={'/login'} /> : null
}

export default withMuiBox(observer(LoginCallback))
