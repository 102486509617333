import { Box } from '@material-ui/core'
import { RfhTypography } from '@rfh/ui'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import accountStore from '../stores/AccountStore'
import { useHistory } from 'react-router'
import RfhButton from '@rfh/ui/components/RfhButton/RfhButton'

const DashboardNavigation = () => {
  const { t } = useTranslation()
  const history = useHistory()

  if (!accountStore.checkIfPermitted(['lmi-fustonline-isklantfusttransactie-'])) {
    return null
  }

  return (
    <Box width='100%' bgcolor='white' p={3}>
      <RfhTypography variant={'h3'}>{t('general:fustOnline')}</RfhTypography>
      <Box display='flex' flexDirection='column' alignItems='flex-start'>
        <RfhButton
          variant={'text--link-icon'}
          onClick={() => history.push('/collecting')}
        >
          {t('general:collecting')}
        </RfhButton>
        <RfhButton
          variant={'text--link-icon'}
          onClick={() => history.push('/returning')}
        >
          {t('general:returning')}
        </RfhButton>
        <RfhButton
          variant={'text--link-icon'}
          onClick={() => history.push('/transactions')}
        >
          {t('general:myTransactions')}
        </RfhButton>
        {accountStore.hasAuthority && (
          <RfhButton
            variant={'text--link-icon'}
            onClick={() => history.push('/machtiging')}
          >
            {t('general:ketenverbanden')}
          </RfhButton>
        )}
      </Box>
    </Box>
  )
}

export default observer(DashboardNavigation)
