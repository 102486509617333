import { FC } from 'react'
import { RfhTypography } from '@rfh/ui'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import RedirectNavigation from '../components/RedirectNavigation'
import { randomizedImageMapper } from '../services/mappers'

const NotFound: FC<unknown> = () => {
  const history = useHistory()
  const { t, i18n } = useTranslation()

  return (
    <Box bgcolor='white' pb={3}>
      <img
        src={randomizedImageMapper()}
        alt='header image page not found'
        style={{
          objectFit: 'cover',
          height: 212,
          maxHeight: 212,
          width: '100%',
        }}
      />
      <Box m={3}>
        <RfhTypography variant='h1'>{t('general:oops')}</RfhTypography>
        <Box>
          <RfhTypography variant='h4'>{t('general:notFoundPage')}</RfhTypography>
          <Box mt={1}>
            <RfhTypography variant='subtitle1'>
              {t('general:notFoundSubtitle')}
            </RfhTypography>
          </Box>
          <RedirectNavigation />
        </Box>
      </Box>
    </Box>
  )
}
export default observer(NotFound)
function _sample(arg0: string[]) {
  throw new Error('Function not implemented.')
}
