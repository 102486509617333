export type BalanceItemType = 'CC' | 'Plaat' | 'Slotplaat' | string
export type Balance = {
  depotId: string
  depotNumber: number
  itemId: number
  itemName: BalanceItemType
  itemNameShort: BalanceItemType
  itemNumber: string
  quantity: number
}
export type MappedBalance = Balance & { image: string }
export type Department = 'Aalsmeer' | 'Eelde' | 'Naaldwijk' | 'Rijnsburg' | string
export type ReturningType = 'InnameMobiel' | 'InnameVast' | string
export type CollectingType =
  | 'UitgifteDock'
  | 'UitgifteDirect'
  | 'UitgifteWinkel'
  | string
export type Info = {
  vestigingen: Department[]
  innameTypes: ReturningType[]
  uitgifteTypes: CollectingType[]
}
export type Depot = {
  naam: string
  vestiging: string
  code: string
}

export type MultiDepot = {
  branch: Department
  transactionType: ReturningType | CollectingType
  depots: Depot[]
}

export enum TransactionItemState {
  Clean = 'S', // Schoon
  Dirty = 'V', // Vuil
  CleanOnPallet = 'P', // Schoon op pallet
  Rest = 'O', // Overig
}

export enum TransactionStatus {
  New = 0, // Nieuw
  Pending = 30, // InBehandeling
  Confirmed = 31, // Bevestigd
  Processed = 35, // Verwerkt
}

export type TransactionRecord = {
  fustCode: number
  itemState: TransactionItemState
  slepen: number
  stapelwagens: number
  stapels: number
  stuks: number
  totaal: number
}
/*
  ItemNumber = "510", ItemName = "510 Diversen bloemendoos"
  ItemNumber = "519", ItemName = "519 Bloemendoos"
  ItemNumber = "525", ItemName = "525 Leliedoos"
  ItemNumber = "527", ItemName = "527 Leliedoos groot"
  ItemNumber = "533", ItemName = "533 Verlaagde exportcontainer"
  ItemNumber = "544", ItemName = "544 Exportcontainer II"
  ItemNumber = "566", ItemName = "566 Kleine container"
  ItemNumber = "577", ItemName = "577 Grote container"
* */
export type Transaction = {
  id: string
  transactieType: ReturningType | CollectingType
  datum: Date | string
  createdOn: Date
  locatie: Department | Depot['code']
  klantLocatie?: string
  klantnummer: number
  vervoerderNummer?: number
  fustAantallen: TransactionRecord[]
  status: TransactionStatus
  externeNotitie?: string
}

export type Ketenverband = {
  id: number | undefined
  name: string | undefined
  role: string
}

export type FustCodeItemState = {
  code: number
  toegestaneItemStates: TransactionItemState[]
}
