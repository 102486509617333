import { FC, useState } from 'react'
import { InputDataGrid, InputDataGridRow } from '@rfh/ui/components/Inputs'
import FormStepsStore from '../../stores/forms/FormStepsStore'
import produce from 'immer'
import { toJS } from 'mobx'
import { TransactionInputFormData } from './types'
import { v4 } from 'uuid'
import { observer } from 'mobx-react'
import { useTransactionValidation } from '../../hooks/useTransactionValidation'
import FormStepsNavigation from './FormStepsNavigation'
import { Box } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import AppLoadingSplash from '../AppLoadingSplash'
import globalStore from '../../stores/GlobalStore'
import { useTranslation } from 'react-i18next'
import {
  defaultTransactionData,
  gridColumns,
} from '../../stores/forms/transactionFormStepsStore'

const TransactionDataGridForm: FC<{
  formStepsStore: FormStepsStore<any, TransactionInputFormData>
}> = ({ formStepsStore }) => {
  const { columns, errors, rows, allFustCodesIsLoading } =
    useTransactionValidation(formStepsStore)
  const history = useHistory()
  const { t } = useTranslation()
  const [displayErrors, setDisplayErrors] = useState<boolean>(false)

  const showNotificationError = () => {
    globalStore.setGlobalAppError({
      errorTitle: t('general:errors.transactionErrorTitle'),
      errorSummary: t('general:errors.transactionErrorMessage'),
    })
  }

  if (allFustCodesIsLoading) {
    return <AppLoadingSplash />
  }
  return (
    <>
      <InputDataGrid
        rows={toJS(rows)}
        columns={gridColumns.map(item => {
          if (item.field === 'col2') {
            return {
              ...item,
              headerName: t('transactions:tr'),
            }
          } else if (item.field === 'col3') {
            return {
              ...item,
              headerName: t('transactions:lp'),
            }
          } else if (item.field === 'col4') {
            return {
              ...item,
              headerName: t('transactions:ps'),
            }
          }
          return item
        })}
        children={
          <InputDataGridRow
            errors={displayErrors ? errors : []}
            handleAddRow={() => {
              setDisplayErrors(true)
              if (errors.length <= 0) {
                const newRowToAdd = {
                  ...defaultTransactionData.rows[0],
                  id: v4(),
                }
                formStepsStore.setFormData(
                  produce(toJS(formStepsStore.formData), draftState => {
                    const oldRows = draftState.transaction.rows
                    draftState.transaction.rows = [
                      ...oldRows.map(row => {
                        return {
                          ...row,
                          ...['col2', 'col3', 'col4'].reduce<
                            Record<string, { value: any }>
                          >((accumulator, key) => {
                            accumulator[key] = {
                              value:
                                String(row[key].value).length <= 0 ? 0 : row[key].value,
                            }
                            return accumulator
                          }, {}),
                        }
                      }),
                      newRowToAdd,
                    ]
                  })
                )
                setDisplayErrors(false)
              } else {
                showNotificationError()
              }
            }}
            handleChangeTextField={(event, field, column, row) => {
              const isNumberWithoutLeadingZero = /^(?!00)([1-9][0-9]*)$/.test(
                event.target.value.toLowerCase()
              )
              const maxLengthUserInput = field === 'col1' ? 3 : field === 'col4' ? 4 : 2

              const newValue = isNumberWithoutLeadingZero
                ? event.target.value.substring(0, maxLengthUserInput)
                : ''

              const newRows = toJS(formStepsStore.formData.transaction.rows).map(item =>
                row.id === item.id
                  ? {
                      ...item,
                      [field]: {
                        ...item[field],
                        value: newValue,
                      },
                    }
                  : item
              )

              formStepsStore.setFormData(
                produce(toJS(formStepsStore.formData), draftState => {
                  draftState.transaction.rows = newRows
                })
              )
            }}
            handleDeleteRow={(event, row) => {
              formStepsStore.setFormData(
                produce(toJS(formStepsStore.formData), draftState => {
                  const findIndex = draftState.transaction.rows.findIndex(
                    item => item.id === row.id
                  )
                  if (findIndex !== -1) {
                    draftState.transaction.rows.splice(findIndex, 1)
                  }
                })
              )
            }}
            rows={toJS(rows)}
            columns={columns}
          />
        }
      />
      <Box m={3}>
        <FormStepsNavigation
          showConfirm={false}
          enableNext={true}
          enableBack={formStepsStore.iterator.hasPrevious()}
          onClickBack={() => history.push(formStepsStore.skipToPreviousPath)}
          onClickNext={() => {
            setDisplayErrors(true)
            if (errors.length <= 0) {
              history.push(formStepsStore.skipToNextPath)
            } else {
              showNotificationError()
            }
          }}
        />
      </Box>
    </>
  )
}

export default observer(TransactionDataGridForm)
