import { AppRoutingPath } from '../../configs/routes.config'
import ArrayIterator from './ArrayIterator'
import { action, computed, makeObservable, observable } from 'mobx'

export default class FormStepsStore<I, T> {
  public formData!: {
    information: I
    transaction: T
  }
  public editTransactionId: string | undefined

  protected _defaultFormData!: {
    information: I
    transaction: T
  }
  private readonly _iterator: ArrayIterator<AppRoutingPath>

  constructor(public routingPaths: AppRoutingPath[]) {
    makeObservable(this, {
      setFormData: action,
      formData: observable,
      resetFormData: action,
      editTransactionId: observable,
      setEditTransactionId: action,
      skipToNextPath: computed,
      skipToPreviousPath: computed,
      isEditMode: computed,
      iterator: computed,
      initFormData: action,
    })
    this._iterator = new ArrayIterator<AppRoutingPath>(routingPaths)
  }

  get iterator() {
    return this._iterator
  }

  setEditTransactionId(id: string | undefined) {
    this.editTransactionId = id
  }

  setFormData(value: { information: I; transaction: T }) {
    this.formData = value
  }

  resetFormData() {
    this.initFormData(this._defaultFormData)
  }

  initFormData(value: { information: I; transaction: T }) {
    this._defaultFormData = value
    this.setFormData(value)
  }

  get isEditMode(): boolean {
    return this.editTransactionId !== undefined
  }

  get skipToNextPath(): string {
    return `${this.iterator.next()}${
      this.editTransactionId ? `/${this.editTransactionId}` : ''
    }`
  }

  get skipToPreviousPath() {
    return `${this.iterator.previous()}${
      this.editTransactionId ? `/${this.editTransactionId}` : ''
    }`
  }
}
