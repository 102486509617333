import { Box } from '@material-ui/core'
import { Button } from '@rfh/ui'
import React, { FC, MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

const FormStepsNavigation: FC<{
  showConfirm?: boolean
  enableNext?: boolean
  enableBack?: boolean
  disableNext?: boolean
  disableBack?: boolean
  onClickBack?: MouseEventHandler
  onClickNext?: MouseEventHandler
  onClickConfirm?: MouseEventHandler
}> = ({
  onClickBack,
  onClickNext,
  onClickConfirm,
  disableNext = false,
  disableBack = false,
  enableBack = true,
  enableNext = true,
  showConfirm = false,
}) => {
  const { t } = useTranslation()
  return (
    <Box display='flex' justifyContent='space-between'>
      <div>
        {enableBack && (
          <Button variant='text--normal' onClick={onClickBack} disabled={disableBack}>
            {t('returning:back')}
          </Button>
        )}
      </div>
      <div>
        {enableNext && (
          <Button
            style={{ paddingRight: 0 }}
            variant={'text--normal'}
            onClick={onClickNext}
            disabled={disableNext}
          >
            {t('returning:next')}
          </Button>
        )}

        {showConfirm && (
          <Button
            variant={'text--normal'}
            onClick={onClickConfirm}
            style={{ paddingRight: 0 }}
          >
            {t('returning:confirm')}
          </Button>
        )}
      </div>
    </Box>
  )
}

export default observer(FormStepsNavigation)
