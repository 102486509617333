import { defaultTransactionData } from './transactionFormStepsStore'
import {
  ReturningFormData,
  TransactionInputFormData,
} from '../../components/forms/types'
import FormStepsStore from './FormStepsStore'
import { createContext } from 'react'
import { AppRoutingPath } from '../../configs/routes.config'

export const defaultReturningFormData: ReturningFormData = {
  location: { label: '', value: '' },
  transactieType: '',
  date: new Date(),
  createdOn: new Date(),
  packagingLocation: { label: '', value: '' },
  customerLocation: { label: '', value: '' },
  note: '',
}

const routingPaths: AppRoutingPath[] = [
  '/returning/step/1',
  '/returning/step/2',
  '/returning/step/3',
]
const defaultFormData = {
  information: defaultReturningFormData,
  transaction: defaultTransactionData,
}
export const returningFormStepsStore = new FormStepsStore<
  ReturningFormData,
  TransactionInputFormData
>(routingPaths)

returningFormStepsStore.initFormData(defaultFormData)

export const NewReturningContext = createContext<
  FormStepsStore<ReturningFormData, TransactionInputFormData>
>(returningFormStepsStore)

export const editReturningFormStepsStore = new FormStepsStore<
  ReturningFormData,
  TransactionInputFormData
>(routingPaths)

editReturningFormStepsStore.initFormData(defaultFormData)
export const EditReturningContext = createContext<
  FormStepsStore<ReturningFormData, TransactionInputFormData>
>(editReturningFormStepsStore)
