type Items<I> = I[]

export default class ArrayIterator<I> {
  private current_index = -1

  constructor(public items: Items<I>, public infinite: boolean = false) {
    this.next()
  }

  reset(): void {
    this.current_index = -1
  }

  next(): Items<I>[number] {
    if (this.infinite) {
      if (this.getCurrentIndex() >= this.getLastIndex()) {
        return this.first()
      } else {
        this.current_index += 1
        return this.items[this.current_index]
      }
    } else {
      const old_index = this.current_index

      if (!this.hasNext()) {
        this.current_index = old_index
        throw new Error(
          'Not allowed to moved to the next item when infinite is set to false. The current index is at the end of the array'
        )
      } else {
        this.current_index += 1
        return this.items[this.current_index]
      }
    }
  }

  previous(): Items<I>[number] {
    if (this.infinite) {
      if (this.getCurrentIndex() <= 0) {
        return this.last()
      } else {
        this.current_index -= 1
        return this.items[this.current_index]
      }
    } else {
      const old_index = this.current_index

      if (!this.hasPrevious()) {
        this.current_index = old_index
        throw new Error(
          'Not allowed to moved to the previous item when infinite is set to false. The current index is at the beginning of the array'
        )
      } else {
        this.current_index -= 1
        return this.items[this.current_index]
      }
    }
  }

  hasNext(): boolean {
    if (this.infinite) return true
    return this.current_index < this.items.length - 1
  }

  hasPrevious(): boolean {
    if (this.infinite) return true
    return this.current_index > 0
  }

  first(): Items<I>[number] {
    this.current_index = this.getFirstIndex()
    return this.items[this.current_index]
  }

  last(): Items<I>[number] {
    this.current_index = this.getLastIndex()
    return this.items[this.current_index]
  }

  getItemByIndex(index: number): Items<I>[number] {
    if (this.items[index] === undefined) return this.last()
    //throw new Error("Item does not exist in array");
    return this.items[index]
  }

  skipToItemByIndex(index: number): Items<I>[number] {
    this.current_index = index
    return this.items[index]
  }

  getItems(): Items<I> {
    return this.items
  }

  getCurrentIndex(): number {
    return this.current_index
  }

  getCurrentItem(): any {
    return this.items[this.current_index]
  }

  getLastIndex(): number {
    return this.items.length - 1
  }

  getFirstIndex(): number {
    return 0
  }
}
