import { action, makeObservable, observable } from 'mobx'
import { AuthState, UserClaims } from '@okta/okta-auth-js'
import { AccessToken, IDToken, RefreshToken } from '@okta/okta-auth-js/lib/types/Token'
import { createContext } from 'react'
import { Config, PrefixPermissionScope } from '../configs/Config'

export type ExtendedUserClaims = UserClaims & {
  customerNumber: number
}

export type Actor = {
  client: string
  id: number | undefined
  role: string
}
export class AccountStore {
  user?: ExtendedUserClaims
  actor?: Actor
  clientNumber?: number
  hasAuthority?: boolean
  accessToken?: AccessToken
  idToken?: IDToken
  refreshToken?: RefreshToken
  isAuthenticated?: boolean
  permittedScopes?: string[]
  permittedActors?: Actor[]
  authStateReady?: boolean = true

  constructor() {
    makeObservable(this, {
      user: observable,
      actor: observable,
      idToken: observable,
      refreshToken: observable,
      isAuthenticated: observable,
      hasAuthority: observable,
      setUser: action,
      setAuthState: action,
      setPermittedScopes: action,
      setPermittedActors: action,
      clearActor: action,
      getActor: action,
      getAuthorized: action,
      setAuthorized: action,
      clearAuthorizer: action,
      permittedScopes: observable,
      checkIfPermitted: observable,
      authStateReady: observable,
      setAuthStateReady: action,
    })
  }

  setUser(user: ExtendedUserClaims): void {
    this.user = user
  }

  setAuthState(authState: AuthState): void {
    this.accessToken = authState.accessToken
    this.idToken = authState.idToken
    this.refreshToken = authState.refreshToken
    this.isAuthenticated = authState.isAuthenticated
  }

  setAuthStateReady(isReady: boolean): void {
    this.authStateReady = isReady
  }

  setPermittedScopes(scopes: string[]): void {
    this.permittedScopes = scopes
  }

  setPermittedActors(actors: Actor[]): void {
    this.permittedActors = actors
  }

  setActor(actor: Actor): void {
    this.actor = actor
    sessionStorage.setItem('actor', JSON.stringify(actor))
  }

  setAuthorized(hasAuthorities: boolean): void {
    this.hasAuthority = hasAuthorities
    sessionStorage.setItem('hasAuthority', hasAuthorities.toString())
  }

  getAuthorized(): void {
    const localItem = sessionStorage.getItem('hasAuthority')
    if (localItem) {
      this.hasAuthority = JSON.parse(localItem.toString()) as boolean
    }
  }

  getActor(): void {
    const localItem = sessionStorage.getItem('actor')
    if (localItem) {
      this.actor = JSON.parse(localItem.toString()) as Actor
    }
  }

  clearActor(): void {
    this.actor = {} as { client: string; id: number; role: string }
    sessionStorage.removeItem('actor')
  }

  clearAuthorizer(): void {
    this.hasAuthority = false
    sessionStorage.removeItem('hasAuthority')
  }

  setClientNumber(clientNumber: number): void {
    this.clientNumber = clientNumber
    sessionStorage.setItem('clientNumber', clientNumber.toString())
  }

  getClientnumber(): void {
    const localItem = sessionStorage.getItem('clientNumber')
    if (localItem) {
      this.clientNumber = JSON.parse(localItem.toString()) as number
    }
  }

  clearClientNumber(): void {
    this.clientNumber = {} as number
    sessionStorage.removeItem('clientNumber')
  }

  checkIfPermitted(prefixScopes: PrefixPermissionScope[]): boolean {
    const A = prefixScopes.map(item => item + Config.env)
    const permitted = A.filter(Set.prototype.has, new Set(this.permittedScopes))
    return permitted && permitted.length > 0
  }
}

const accountStore = new AccountStore()
export const AccountStoreContext = createContext<AccountStore>(accountStore)
export default accountStore
