import { AppNamespace } from '../config'
import collecting from './collecting.json'
import general from './general.json'
import returning from './returning.json'
import transactions from './transactions.json'

const namespaces: AppNamespace = {
  collecting,
  general,
  transactions,
  returning,
}
export default namespaces
