import { Button } from '@rfh/ui'
import { FC, MouseEventHandler } from 'react'
import Dialog from './Dialog'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import useOnlineOffline from '@rfh/ui/shared/hooks/useOnlineOffline'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonProgress: {
      color: theme.rfhColors.hydroGreen,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
)
const ConfirmOrderDialog: FC<{
  handleAddNoteClick: MouseEventHandler
  handleConfirmClick: MouseEventHandler
  handleBackClick: MouseEventHandler
  isProcessing?: boolean
  note?: string
  isEditMode: boolean
  type: string
}> = ({
  handleAddNoteClick,
  handleConfirmClick,
  handleBackClick,
  isProcessing = false,
  note,
  isEditMode,
  type,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const styles = useStyles()
  const { offline } = useOnlineOffline()

  const buttons = [
    <Button fullWidth variant='outlined' onClick={handleBackClick}>
      {t('collecting:backToCollectingOrder')}
    </Button>,
    <Button fullWidth variant='outlined' onClick={handleAddNoteClick}>
      {note ? t('transactions:editNote') : t('collecting:addANote')}
    </Button>,
    <Button
      disabled={offline}
      fullWidth
      variant='block--contained'
      onClick={handleConfirmClick}
      isLoading={isProcessing}
    >
      {t('collecting:confirmOrder')}
    </Button>,
  ]

  const buttonsWithoutNote = []
  buttonsWithoutNote.push(buttons[0], buttons[2])

  return (
    <Dialog
      open={true}
      closable={false}
      title={
        type === 'collecting'
          ? t('collecting:confirmCollectingOrder')
          : t('returning:confirmReturningOrder')
      }
      contentText={t('collecting:haveYouFilledInAllTheDetailsCorrectly')}
      buttons={isEditMode ? buttonsWithoutNote : buttons}
    />
  )
}
export default observer(ConfirmOrderDialog)
