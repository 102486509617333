import { InputDataGridColumn } from '@rfh/ui/components/Inputs/InputDataGrid'
import { useEffect, useState } from 'react'
import {
  CollectingFormData,
  ReturningFormData,
  TransactionInputFormData,
} from 'src/components/forms/types'
import ApiClient from '../services/api/ApiClient'
import FormStepsStore from '../stores/forms/FormStepsStore'
import { InputDataGridRowError } from '@rfh/ui/components/Inputs/InputDataGridRow'
import _ from 'lodash'
import { GridRowData } from '@material-ui/data-grid'
import { gridColumns } from '../stores/forms/transactionFormStepsStore'
import { FustCodeItemState } from '../services/api/types'
import globalStore from '../stores/GlobalStore'

export const useTransactionValidation = (
  formStepsStore: FormStepsStore<
    CollectingFormData | ReturningFormData,
    TransactionInputFormData
  >
): {
  columns: InputDataGridColumn[]
  errors: InputDataGridRowError[]
  rows: GridRowData[]
  allFustCodesIsLoading: boolean
} => {
  const rows = formStepsStore.formData.transaction.rows
  const [allFustCodes, setAllFustCodes] = useState<number[]>([])
  const [columns, setColumns] = useState<InputDataGridColumn[]>([])
  const [errors, setErrors] = useState<InputDataGridRowError[]>([])
  const [allFustCodesIsLoading, setAllFustCodesIsLoading] = useState<boolean>(false)
  const validatorFunction = async (
    _fustCodes: number[],
    _userInputFustCode: string
  ) => {
    const isValid = _fustCodes.map(String).includes(String(_userInputFustCode))
    return Promise.resolve({
      isValid,
    })
  }

  const createNewColumns = () => {
    return gridColumns.map(column => {
      if (column.headerName === 'Code') {
        return {
          ...column,
          validate: (val: any) => validatorFunction(allFustCodes, val),
        }
      } else {
        return {
          ...column,
          validate: (userInputValue: number | string) => {
            const isValid =
              (typeof userInputValue === 'number' && userInputValue > 0) ||
              (typeof userInputValue === 'string' && Number(userInputValue) > 0)

            return Promise.resolve({ isValid })
          },
        }
      }
    })
  }
  const depotCode = formStepsStore.formData.information.packagingLocation.id
  useEffect(() => {
    if (depotCode) {
      setAllFustCodesIsLoading(true)
      ApiClient.get<FustCodeItemState[]>(`fustcodes/${depotCode}`)
        .then(res => {
          globalStore.setFustCodeItemStates(res.data)
          const _allFustCodes: number[] = res.data?.map(fust => fust.code) || []
          setAllFustCodes(_allFustCodes)
          setAllFustCodesIsLoading(false)
        })
        .catch(() => {
          setAllFustCodesIsLoading(false)
        })
    } else {
      setAllFustCodesIsLoading(false)
    }
  }, [depotCode])

  useEffect(() => {
    setColumns(createNewColumns())
  }, [allFustCodes])

  useEffect(() => {
    setColumns(createNewColumns())
  }, [rows])

  useEffect(() => {
    const promises = _.flatten(
      rows.map(row => {
        const oneOfTheValidationFields = columns
          .filter(
            column =>
              column.headerName === 'Stw' ||
              column.headerName === 'Stpl' ||
              column.headerName === 'Stk'
          )
          .map(item => item.field)
        const validateOneOf =
          _.chain(row)
            .pick(oneOfTheValidationFields)
            .values()
            .map(({ value }) => Number(value))
            .filter(_value => _value > 0)
            .value().length >= 1

        return columns.map(async (column, columnIndex) => {
          const value = row[column.field].value
          const validationInformation = {
            rowId: row.id,
            colName: column.field,
            value,
          }
          return validateOneOf && oneOfTheValidationFields.includes(column.field)
            ? {
                ...validationInformation,
                validation: {
                  isValid: Number(value) === 0 || Number(value) > 0,
                },
              }
            : {
                ...validationInformation,
                validation: column.validate && (await column.validate(value)),
              }
        })
      })
    )

    Promise.all(promises).then((promiseResult: any) => {
      setErrors(
        promiseResult.filter(
          (error: any) => error.validation && !error.validation.isValid
        )
      )
    })
  }, [columns])

  return { columns, errors, rows, allFustCodesIsLoading }
}
