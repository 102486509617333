import { FC, useState, ChangeEvent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { appRoutesConfig, AppRoutingPath } from '../../../configs/routes.config'
import { Tabs, Tab, Box } from '@material-ui/core'
import accountStore from '../../../stores/AccountStore'
import NoPermission from '../../../views/NoPermission'

const Transactions: FC<unknown> = () => {
  const [active, setActive] = useState(0)
  const handleChange = (event: ChangeEvent<any>, newValue: number) => {
    setActive(newValue)
  }
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const paths = [
    '/transactions/open' as AppRoutingPath,
    '/transactions/closed' as AppRoutingPath,
  ]

  useEffect(() => {
    const index = paths.findIndex(item => item === location.pathname)
    setActive(index === -1 ? 0 : index)
  }, [location.pathname])

  if (
    accountStore.permittedScopes?.length &&
    !accountStore.checkIfPermitted(['lmi-fustonline-isklantfusttransactie-'])
  ) {
    return <NoPermission />
  }

  return (
    <>
      <Tabs
        value={active}
        onChange={handleChange}
        variant='fullWidth'
        style={{ position: 'fixed', width: '100%', zIndex: 5 }}
      >
        <Tab
          label={t('transactions:open')}
          onClick={() => history.push('/transactions/open')}
        />
        <Tab
          label={t('transactions:closed')}
          onClick={() => history.push('/transactions/closed')}
        />
      </Tabs>
      <Switch>
        {paths.map(path => (
          <Route key={path} exact={appRoutesConfig[path].exact} path={path}>
            <Box mt={6}>{appRoutesConfig[path].component}</Box>
          </Route>
        ))}
        <Route>
          <Redirect to={'/transactions/open' as AppRoutingPath} />
        </Route>
      </Switch>
    </>
  )
}
export default observer(Transactions)
