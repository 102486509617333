import { QueryClient as ReactQueryClient } from 'react-query'
import { persistQueryClient } from 'react-query/persistQueryClient-experimental'
import { createLocalStoragePersistor } from 'react-query/createLocalStoragePersistor-experimental'
import globalStore from '../../stores/GlobalStore'
import { Config } from '../../configs/Config'
import { captureException, setTag } from '@sentry/react'

export const QueryClient = new ReactQueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retryDelay: attemptIndex =>
        Math.min(1000 * 2 ** attemptIndex, Config.maxRetryDelay),
      onError: err => {
        const errorObject = JSON.parse(JSON.stringify(err))
        globalStore.setGlobalAppError({
          errorSummary: errorObject.config.url,
          errorTitle: errorObject.message,
        })
        if (!(err as Error).message.toLowerCase().includes('network error')) {
          setTag('errorLocation', 'QueryClient')
          captureException(err)
        }
      },
    },
  },
})

persistQueryClient({
  queryClient: QueryClient,
  persistor: createLocalStoragePersistor(),
})
