import { RfhTypography } from '@rfh/ui'
import { observer } from 'mobx-react'
import accountStore from 'src/stores/AccountStore'
accountStore.getActor()
accountStore.getAuthorized()

const UserInformation = () => {
  if (!accountStore.checkIfPermitted(['lmi-fustonline-isklantfusttransactie-'])) {
    return null
  }

  return (
    <RfhTypography
      variant={'body1'}
      style={
        accountStore.hasAuthority && accountStore.actor?.role !== 'Eigen gebruik'
          ? {
              padding: 5,
              paddingLeft: 25,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              background: 'yellow',
            }
          : {
              padding: 5,
              paddingLeft: 25,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }
      }
    >
      {accountStore.hasAuthority
        ? `adm.nr: ${accountStore.actor?.id} - ${accountStore.actor?.client}`
        : `adm.nr:  ${accountStore.user?.customerNumber} - ${accountStore.user?.family_name}`}
    </RfhTypography>
  )
}
export default observer(UserInformation)
