import { Box, FormHelperText } from '@material-ui/core'
import { DatePicker, RfhTypography } from '@rfh/ui'
import { createRef, FC, FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import produce from 'immer'
import { toJS } from 'mobx'
import TypesRadioGroup from './TypesRadioGroup'
import BrancheSelect from './BrancheSelect'
import DepotSelect from './DepotSelect'
import FormStepsNavigation from './FormStepsNavigation'
import OpeninghoursCard from '../OpeninghoursCard'
import CustomerLocationSelect from './CustomerLocationSelect'
import useMultiDepots from '../../hooks/api/useMultiDepots'
import _ from 'lodash'
import { CollectingType, Depot, ReturningType } from '../../services/api/types'
import { useCustomerLocations } from '../../hooks/api'
import { observer } from 'mobx-react'
import AppLoadingSplash from '../AppLoadingSplash'
import useTransactionForm from '../../hooks/useTransactionForm'
import ReloadButton from '../ReloadButton'

const InformationForm: FC<{
  variant: 'collecting' | 'returning'
}> = ({ variant }) => {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const { formStepsStore } = useTransactionForm(variant)
  const { formData } = formStepsStore
  const {
    information: {
      location,
      date,
      packagingLocation,
      transactieType,
      customerLocation,
    },
  } = formData

  const {
    isLoading: multiDepotsIsLoading,
    isSuccess: multiDepotsIsSuccess,
    data: multiDepotsPayload,
    refetch,
  } = useMultiDepots(variant)
  const { isLoading: customerLocationsIsLoading, data: customerLocationsPayload } =
    useCustomerLocations()

  const branches = _.chain(multiDepotsPayload?.data).groupBy('branch').keys().value()

  const [transactionTypes, setTransactionTypes] = useState<
    (CollectingType | ReturningType)[]
  >([])

  const [depots, setDepots] = useState<Depot[]>([])

  useEffect(() => {
    setTransactionTypes(
      _.chain(multiDepotsPayload?.data)
        .filter(item => location.value === item.branch && item.depots.length > 0)
        .groupBy('transactionType')
        .keys()
        .value()
    )
    setDepots(
      _.chain(multiDepotsPayload?.data)
        .filter(
          item =>
            location.value === item.branch && item.transactionType === transactieType
        )
        .first()
        .value()?.depots || []
    )
  }, [location.value, transactieType, multiDepotsPayload])

  const submitRef = createRef<HTMLInputElement>()

  const [errors, setErrors] = useState<{
    location: boolean
    date: boolean
    packagingLocation: boolean
    transactieType: boolean
    customerLocation: boolean
  }>({
    location: false,
    date: false,
    packagingLocation: false,
    transactieType: false,
    customerLocation: false,
  })

  const [submitForm, setSubmitForm] = useState<boolean>(false)

  useEffect(() => {
    setErrors({
      location: !branches.includes(location.value.toString()),
      date: date === null,
      transactieType:
        location.value.toString().length > 0 &&
        !transactionTypes.includes(transactieType),
      packagingLocation: !depots
        .map(item => String(item.naam))
        .includes(packagingLocation.value.toString()),
      customerLocation: (() => {
        const customerlocations = customerLocationsPayload?.data || []
        const _type =
          variant === 'collecting'
            ? 'UitgifteDirect'
            : variant === 'returning'
            ? 'InnameMobiel'
            : undefined

        if (customerlocations.length && _type === transactieType) {
          return !customerlocations.includes(customerLocation.value.toString())
        }
        return false
      })(),
    })
  }, [formData, depots, transactionTypes])

  const noLocation = multiDepotsIsSuccess && branches.length <= 0
  const locationAndPackagingLocationSelected =
    location.value.toString().length > 0 && transactionTypes.length > 0
  const noPackagingLocation =
    location.value.toString().length > 0 && transactionTypes.length <= 0

  const translatedVariant =
    variant === 'collecting' ? t('collecting:collecting') : t('returning:returning')
  const translatedSelectDate =
    variant === 'collecting' ? t('collecting:selectDate') : t('returning:selectDate')

  const handleSubmitForm = (event: FormEvent) => {
    event.preventDefault()

    const formIsValid =
      _.chain(errors)
        .map(item => item)
        .filter(item => item === true)
        .value().length <= 0

    if (formIsValid) {
      history.push(formStepsStore.skipToNextPath)
    }
  }
  if (multiDepotsIsLoading || customerLocationsIsLoading) {
    return <AppLoadingSplash />
  }
  return (
    <>
      <form onSubmit={handleSubmitForm}>
        <Box pb={1}>
          {!noLocation ? (
            <BrancheSelect
              branches={branches}
              helperText={
                submitForm && errors?.location ? t('general:errors.forms.required') : ''
              }
              error={submitForm && errors?.location}
              defaultValue={location.value}
              placeholder={t((variant + ':selectLocation') as any)}
              formStepsStore={formStepsStore}
            />
          ) : noLocation ? (
            <ReloadButton
              message={t('general:errors.noFormElements.location')}
              handleRefetchClick={() => refetch({ cancelRefetch: true })}
            />
          ) : null}
        </Box>
        {locationAndPackagingLocationSelected && (
          <Box pt={2}>
            <RfhTypography variant='subtitle1'>{translatedVariant}</RfhTypography>
          </Box>
        )}
        <Box>
          <>
            {multiDepotsIsSuccess && submitForm && errors?.transactieType && (
              <Box pt={1}>
                <FormHelperText error={true}>
                  {t('general:errors.forms.required')}
                </FormHelperText>
              </Box>
            )}
            <TypesRadioGroup
              formStepsStore={formStepsStore}
              transactionTypes={transactionTypes}
              branch={location.value.toString()}
              variant={variant}
              value={transactieType}
            />
          </>
          {multiDepotsIsSuccess && noPackagingLocation && (
            <ReloadButton
              message={t('general:errors.noFormElements.depots')}
              handleRefetchClick={() => refetch({ cancelRefetch: true })}
            />
          )}
        </Box>
        <Box pb={1}>
          <Box pt={2}>
            <RfhTypography variant='subtitle1'>{translatedSelectDate}</RfhTypography>
          </Box>
          <DatePicker
            helperText={
              submitForm && errors?.date ? t('general:errors.forms.required') : ''
            }
            required={true}
            error={submitForm && errors?.date}
            dayPlaceholder={t('general:datePickerDayPlaceholder')}
            monthPlaceholder={t('general:datePickerMonthPlaceholder')}
            yearPlaceholder={t('general:datePickerYearPlaceholder')}
            locale={i18n.language === 'nl' ? 'nl-NL' : 'en-US'}
            minDate={new Date()}
            value={date}
            onChange={dates => {
              formStepsStore.setFormData(
                produce(toJS(formData), draftState => {
                  draftState.information.date = dates
                })
              )
            }}
          />
        </Box>
        <Box pt={1}>
          <DepotSelect
            helperText={
              submitForm && errors?.packagingLocation
                ? t('general:errors.forms.required')
                : ''
            }
            error={submitForm && errors?.packagingLocation}
            depots={depots}
            defaultValue={packagingLocation.value}
            placeholder={t((variant + ':selectAPackagingLocation') as any)}
            type={transactieType}
            branch={location.value.toString()}
            formStepsStore={formStepsStore}
          />
        </Box>
        <Box pt={1}>
          <CustomerLocationSelect
            customerLocations={customerLocationsPayload?.data || []}
            helperText={
              submitForm && errors?.customerLocation
                ? t('general:errors.forms.required')
                : ''
            }
            error={submitForm && errors?.customerLocation}
            type={transactieType}
            defaultValue={customerLocation.value}
            placeholder={t((variant + ':selectACustomerLocation') as any)}
            formStepsStore={formStepsStore}
          />
        </Box>
        <Box my={3}>
          <OpeninghoursCard type={variant} department={location.value.toString()} />
        </Box>
        <FormStepsNavigation
          showConfirm={false}
          enableNext={formStepsStore.iterator.hasNext()}
          enableBack={true}
          onClickBack={() => history.goBack()}
          onClickNext={() => {
            setSubmitForm(true)
            submitRef.current?.click()
          }}
        />
        <input hidden={true} ref={submitRef} type={'submit'} value={'submit'} />
      </form>
    </>
  )
}
export default observer(InformationForm)
