import { Button, CallToActionIcon, RfhTypography } from '@rfh/ui'
import { observer } from 'mobx-react'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import { moreInformationUrl } from '../../i18n/config'
import { useOktaAuth } from '@okta/okta-react'
import globalStore from '../../stores/GlobalStore'
import { captureException, setTag } from '@sentry/react'

const LoginPage: FC<unknown> = () => {
  const { t, i18n } = useTranslation()
  const { oktaAuth } = useOktaAuth()

  return (
    <Box bgcolor='white' pb={3}>
      <img
        src='/assets/images/login-header.png'
        alt='header intro image'
        style={{
          objectFit: 'cover',
          height: 212,
          maxHeight: 212,
          width: '100%',
        }}
      />
      <Box m={3}>
        <RfhTypography variant='h1'>{t('general:fustOnline')}</RfhTypography>
        <Box my={2} mt={1}>
          <RfhTypography variant='h4'>{t('general:login.introText')}</RfhTypography>
        </Box>
        <Button
          variant='text--link-icon'
          onClick={() =>
            window.location.assign(
              moreInformationUrl[i18n.language === 'nl' ? 'nl' : 'en']
            )
          }
        >
          {t('general:login.moreInformation')}
        </Button>
        <Box my={2}>
          <CallToActionIcon
            title={t('general:login.fustOnlineUser')}
            linkText={t('general:login.login')}
            onActionClick={async () => {
              try {
                await oktaAuth.signInWithRedirect()
              } catch (error) {
                globalStore.setGlobalAppError({
                  errorTitle: 'oktaAuth.signInWithRedirect()',
                  errorSummary: JSON.stringify(error, null, 4),
                })
                setTag('errorLocation', 'LoginPage')
                captureException(error)
              }
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}
export default observer(LoginPage)
