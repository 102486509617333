import React, { FC, memo } from 'react'
import { RfhSelectProps, SelectItemProps } from '@rfh/ui/components/Inputs/RfhSelect'
import { ActorFormData, ActorTypes } from './types'
import { Box, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import ActorTypesRadioGroup from './ActorTypesRadioGroup'
import { Actor } from 'src/stores/AccountStore'
import { RfhTypography } from '@rfh/ui'
import { Ketenverband } from 'src/services/api/types'
import { useTranslation } from 'react-i18next'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: theme.rfhColors.white,
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiAutocomplete-input': {
        cursor: 'pointer',
        padding: '0px!important',
      },
    },
    popper: {
      marginTop: '-4px',
    },
    option: {
      fontSize: '16px',
      background: 'none',
      padding: '2px 16px',
      '&:hover': {
        background: 'none',
        color: theme.rfhColors.hydroGreen,
      },
      '&:selected': {
        fontWeight: '700',
      },
    },
  })
)

const ActorSelect: FC<
  Omit<RfhSelectProps, 'items'> & {
    actors: Ketenverband[]
    actorTypes: ActorTypes[]
    actorForm: ActorFormData
    defaultActorValue?: number
    defaultActorTypeValue?: string
    defaultTitle: string
    handleActorEvent: any
    changedRole: string
  }
> = props => {
  const {
    actorForm,
    defaultActorValue,
    defaultActorTypeValue,
    defaultTitle,
    actors,
    actorTypes,
    handleActorEvent,
    changedRole,
    ...rest
  } = props

  const { t } = useTranslation()

  const classes = useStyles()

  const filteredActors = (): SelectItemProps[] => {
    return actors
      .filter(cs => cs.role === changedRole)
      .map(actor => ({
        id: actor.id,
        label: `${actor?.id} - ${actor?.name}`,
        value: actor.id || 0,
      }))
  }
  return (
    <form>
      <Box pb={1} style={{ padding: 10 }}>
        <RfhTypography variant={'subtitle2'}>{defaultTitle}</RfhTypography>
        <br />
        <ActorTypesRadioGroup
          actorForm={actorForm}
          actorTypes={actorTypes}
          defaultValue={defaultActorTypeValue}
          handleChangeDropdown={(event: any) => {
            handleActorEvent(event)
          }}
        />
        <Autocomplete
          id='Autocomplete customer'
          classes={{
            root: classes.root,
            option: classes.option,
            popper: classes.popper,
          }}
          options={filteredActors() || []}
          getOptionLabel={option => option.label}
          onChange={(event, item): void => {
            actorForm.customer = {
              client: item?.label.split('-')[1],
              id: item?.id,
              role: changedRole,
            } as Actor
          }}
          renderInput={params => (
            <TextField
              {...params}
              variant='outlined'
              placeholder={t('general:selectActor')}
            />
          )}
        />
      </Box>
    </form>
  )
}

export default memo(ActorSelect)
