import { Button, RfhTypography } from '@rfh/ui'
import { observer } from 'mobx-react'
import React, { FC } from 'react'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import { useOktaAuth } from '@okta/okta-react'
import { openinghoursMapper } from 'src/services/mappers'
import { openingshoursUrl } from 'src/i18n/config'

const Openinghours: FC<unknown> = () => {
  const history = useHistory()
  const { t, i18n } = useTranslation()
  const { oktaAuth } = useOktaAuth()
  const departments = ['Aalsmeer', 'Eelde', 'Naaldwijk', 'Rijnsburg']
  return (
    <Box bgcolor='white' pb={3}>
      <img
        src='/assets/images/openingshourcard-xl.png'
        alt='header openinghours image'
        style={{
          objectFit: 'cover',
          height: 212,
          maxHeight: 212,
          width: '100%',
        }}
      />
      <Box m={3}>
        <RfhTypography variant='h1'>{t('collecting:openingHours')}</RfhTypography>
        <Box my={2} mt={1}>
          <RfhTypography variant='h4'>
            {t('collecting:theOpeningHoursAreLocationSpecific')}
          </RfhTypography>
        </Box>
        <Box display='flex'>
          <Box display='flex' flexDirection='column' alignItems='flex-start' mr={6}>
            <RfhTypography variant='subtitle1'>
              {t('collecting:collecting')}
            </RfhTypography>
            {i18n.language === 'nl'
              ? departments.map((department, index) => (
                  <Button
                    key={index}
                    variant='text--link-icon'
                    onClick={() =>
                      window.location.assign(
                        [
                          openingshoursUrl.nl,
                          openinghoursMapper(department).collectNlUrl,
                        ].join('/')
                      )
                    }
                  >
                    {department}
                  </Button>
                ))
              : departments.map((department, index) => (
                  <Button
                    key={index}
                    variant='text--link-icon'
                    onClick={() =>
                      window.location.assign(
                        [
                          openingshoursUrl.en,
                          openinghoursMapper(department).collectEnUrl,
                        ].join('/')
                      )
                    }
                  >
                    {department}
                  </Button>
                ))}
          </Box>
          <Box display='flex' flexDirection='column' alignItems='flex-start'>
            <RfhTypography variant='subtitle1'>
              {t('returning:returning')}
            </RfhTypography>
            {i18n.language === 'nl'
              ? departments.map((department, index) => (
                  <Button
                    key={index}
                    variant='text--link-icon'
                    onClick={() =>
                      window.location.assign(
                        [
                          openingshoursUrl.nl,
                          openinghoursMapper(department).returnNlUrl,
                        ].join('/')
                      )
                    }
                  >
                    {department}
                  </Button>
                ))
              : departments.map((department, index) => (
                  <Button
                    key={index}
                    variant='text--link-icon'
                    onClick={() =>
                      window.location.assign(
                        [
                          openingshoursUrl.en,
                          openinghoursMapper(department).returnEnUrl,
                        ].join('/')
                      )
                    }
                  >
                    {department}
                  </Button>
                ))}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
export default observer(Openinghours)
