import { FC } from 'react'
import Dialog from './Dialog'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router'

const ConfirmDeleteDialog: FC<{
  id: string
  handleCloseClick: () => void
}> = ({ handleCloseClick, id }) => {
  const { t } = useTranslation()
  const history = useHistory()
  return (
    <Dialog
      open={true}
      handleCloseClick={handleCloseClick}
      title={t('transactions:orderDeleted')}
      contentText={
        t('transactions:yourOrderWithTheNumberTransactionNumberHasBeenDeleted', {
          transactionNumber: id,
        }) as string
      }
    />
  )
}
export default observer(ConfirmDeleteDialog)
