import { action, makeObservable, observable } from 'mobx'
import { AuthApiError } from '@okta/okta-auth-js'
import { createContext } from 'react'
import { FustCodeItemState } from '../services/api/types'

export type GlobalAppError = Partial<AuthApiError> & { errorTitle?: string }

export class GlobalStore {
  globalAppError: GlobalAppError | undefined
  fustCodeItemStates: FustCodeItemState[] | undefined

  constructor() {
    makeObservable(this, {
      globalAppError: observable,
      setGlobalAppError: action,
      fustCodeItemStates: observable,
      setFustCodeItemStates: action,
    })
  }

  setGlobalAppError(error: GlobalAppError | undefined) {
    this.globalAppError = error
  }

  setFustCodeItemStates(fustCodeItemStates: FustCodeItemState[] | undefined) {
    this.fustCodeItemStates = fustCodeItemStates
  }
}

const globalStore = new GlobalStore()
export const GlobalStoreContext = createContext<GlobalStore>(globalStore)
export default globalStore
