import { observer } from 'mobx-react'
import { FC } from 'react'
import CollectingForm from '../../../components/forms/CollectingForm'
import withMuiBox from '../../../hocs/withMuiBox'
import useTransactionForm from '../../../hooks/useTransactionForm'
import InformationFormDisplay from '../../../components/forms/InformationFormDisplay'

const Collecting_1: FC<unknown> = () => {
  const { isEditMode, iterator, formStepsStore } = useTransactionForm('collecting')
  const { formData, skipToNextPath } = formStepsStore
  const { information } = formData

  return (
    <>
      {!isEditMode ? (
        <CollectingForm />
      ) : (
        <InformationFormDisplay
          variant={'collecting'}
          iterator={iterator}
          information={information}
          skipToNextPath={skipToNextPath}
        />
      )}
    </>
  )
}

export default withMuiBox(observer(Collecting_1))
