import React from 'react'
import i18n from 'i18next'
import enTranslations from './en/index'
import nlTranslations from './nl/index'
import { initReactI18next, Namespace, TFunction } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { FlagGB, FlagNL } from '@rfh/ui/shared/icons'

export type AppLanguageCode = 'nl' | 'en'
export type AppNamespace = Record<
  'collecting' | 'general' | 'returning' | 'transactions',
  any
>
export type AppTFunction = TFunction<Namespace<keyof AppNamespace>>
export const fallbackLng = 'nl' as AppLanguageCode
export type AppLanguageConfig = Record<
  AppLanguageCode,
  { flagIcon: JSX.Element; languageName: Record<AppLanguageCode, string> }
>
export const appLanguageConfig: AppLanguageConfig = {
  en: {
    flagIcon: <FlagGB />,
    languageName: {
      en: 'English',
      nl: 'Dutch',
    },
  },
  nl: {
    flagIcon: <FlagNL />,
    languageName: {
      en: 'Engels',
      nl: 'Nederlands',
    },
  },
}

export const resources = {
  en: {
    ...enTranslations,
  },
  nl: {
    ...nlTranslations,
  },
} as const

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng,
    ns: [
      'collecting',
      'returning',
      'transactions',
      'general',
    ] as (keyof AppNamespace)[],
    resources,
  })
i18n.language = Object.keys(appLanguageConfig).includes(i18n.language)
  ? i18n.language
  : fallbackLng
export default i18n

export const appDatetimeFormat = {
  nl: 'DD-MM-YY',
  en: 'MM/DD/YY',
  nl_time: 'DD-MM-YY HH:mm',
  en_time: 'MM/DD/YY HH:mm',
}

export const openingshoursUrl = {
  nl: 'https://www.royalfloraholland.com/inkopen/logistiek/logistieke-middelen/alles-over-fust/inname-en-uitgifte-fust',
  en: 'https://www.royalfloraholland.com/en/buying/logistics/logistic-resources/all-about-packaging/collecting-and-returning-packaging',
}

export const moreInformationUrl = {
  nl: 'https://www.royalfloraholland.com/nl/inkopen/logistiek/logistieke-middelen/fust-online-nod55779',
  en: 'https://www.royalfloraholland.com/en/buying/logistics/logistic-supplies/packaging-online-nod67211',
}
