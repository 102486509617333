import { CardHorizontal } from '@rfh/ui'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { AppRoutingPath } from 'src/configs/routes.config'
import { openingshoursUrl } from 'src/i18n/config'
import { openinghoursMapper } from 'src/services/mappers'

const OpeninghoursCard: FC<{ type: string; department: string }> = ({
  type,
  department,
}) => {
  const cardHeight = 136
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const titleType =
    type === 'collecting' ? t('collecting:collecting') : t('returning:returning')

  const url = [
    openingshoursUrl[i18n.language === 'nl' ? 'nl' : 'en'],
    i18n.language === 'nl'
      ? type === 'collecting'
        ? openinghoursMapper(department).collectNlUrl
        : openinghoursMapper(department).returnNlUrl
      : type === 'collecting'
      ? openinghoursMapper(department).collectEnUrl
      : openinghoursMapper(department).returnEnUrl,
  ].join('/')

  return (
    <>
      {!department ? (
        <CardHorizontal
          title={t('collecting:openingHours')}
          contentText={t('collecting:theOpeningHoursAreLocationSpecific')}
          linkText={t('collecting:openingHours')}
          imgSrc='/assets/images/openingshourcard.png'
          onClick={() => {
            history.push('/openinghours' as AppRoutingPath)
          }}
          height={cardHeight}
        />
      ) : (
        <CardHorizontal
          title={[titleType, department].join(' ')}
          contentText={t('collecting:theOpeningHoursAreLocationSpecific')}
          linkText={t('collecting:openingHours')}
          imgSrc='/assets/images/openingshourcard.png'
          onClick={() => {
            window.location.assign(url)
          }}
          height={cardHeight}
        />
      )}
    </>
  )
}

export default OpeninghoursCard
