import { observer } from 'mobx-react'
import { FC, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import RedirectDialog from '../../../components/dialogs/RedirectDialog'
import { AppRoutingPath } from '../../../configs/routes.config'
import ConfirmOrderDialog from '../../../components/dialogs/ConfirmOrderDialog'
import AddNoteDialog from '../../../components/dialogs/AddNoteDialog'
import produce from 'immer'
import { toJS } from 'mobx'
import { v4 } from 'uuid'
import { CollectingType, TransactionStatus } from '../../../services/api/types'
import { AccountStoreContext } from '../../../stores/AccountStore'
import { transactionRecordMapper } from '../../../services/mappers'
import { usePostTransactionHelper, usePutTransactionHelper } from '../../../hooks/api'
import { useTransactionForm } from '../../../hooks'
import { convertToDateString } from '../../../services/formatters'

const Collecting_3: FC<unknown> = () => {
  const history = useHistory()
  const [confirmCollecting, setConfirmCollecting] = useState(true)
  const [addNoteDialog, setAddNoteDialog] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const { user } = useContext(AccountStoreContext)
  const { formStepsStore, isEditMode, matchedTransactionId } =
    useTransactionForm('collecting')
  const [addNote, setAddNote] = useState<string | undefined>(
    formStepsStore.formData.information.note
  )
  const newTransaction = usePostTransactionHelper({
    onSuccess: () => {
      setConfirmCollecting(false)
      formStepsStore.resetFormData()
      setRedirect(true)
    },
  })

  const editTransaction = usePutTransactionHelper({
    onSuccess: () => {
      setConfirmCollecting(false)
      formStepsStore.resetFormData()
      setRedirect(true)
    },
  })

  const {
    information: {
      packagingLocation,
      customerLocation,
      date,
      createdOn,
      location: { value: branch },
      note,
      transactieType,
    },
    transaction: { rows },
  } = formStepsStore.formData

  const { mutate: mutateTransaction, isLoading: mutateTransactionLoading } = isEditMode
    ? editTransaction
    : newTransaction

  return (
    <>
      {confirmCollecting ? (
        <ConfirmOrderDialog
          type={'collecting'}
          isEditMode={isEditMode}
          note={formStepsStore.formData.information.note}
          isProcessing={mutateTransactionLoading}
          handleAddNoteClick={() => {
            setConfirmCollecting(false)
            setAddNoteDialog(true)
          }}
          handleConfirmClick={() => {
            mutateTransaction({
              fustAantallen: toJS(rows).map(row =>
                transactionRecordMapper(row, rows, { type: transactieType })
              ),
              id: matchedTransactionId ? matchedTransactionId : v4(),
              klantnummer: toJS(user)?.customerNumber as number,
              locatie: (packagingLocation.id ? packagingLocation.id : branch) as string,
              ...(customerLocation.value.toString().length
                ? { klantLocatie: customerLocation.value.toString() }
                : null),
              status: TransactionStatus.New,
              transactieType: transactieType as CollectingType,
              datum: convertToDateString(date || new Date()),
              createdOn,
              externeNotitie: note,
            })
          }}
          handleBackClick={() => {
            setConfirmCollecting(false)
            history.push(formStepsStore.skipToPreviousPath)
          }}
        />
      ) : addNoteDialog ? (
        <AddNoteDialog
          note={addNote}
          handleChangeNote={event => setAddNote(event.target.value)}
          handleCancelClick={() => {
            setAddNote(formStepsStore.formData.information.note)
            setAddNoteDialog(false)
            setConfirmCollecting(true)
          }}
          handleConfirmClick={() => {
            formStepsStore.setFormData(
              produce(toJS(formStepsStore.formData), draftState => {
                draftState.information.note = addNote
              })
            )
            setAddNoteDialog(false)
            setConfirmCollecting(true)
          }}
        />
      ) : redirect ? (
        <RedirectDialog
          handleMainMenuClick={() => {
            setRedirect(false)
            history.push('/' as AppRoutingPath)
          }}
          handleTransactionClick={() => {
            setRedirect(false)
            history.push('/transactions' as AppRoutingPath)
          }}
          handleNewOrderClick={() => {
            setRedirect(false)
            history.push(formStepsStore.iterator.first() as AppRoutingPath)
          }}
        />
      ) : null}
    </>
  )
}
export default observer(Collecting_3)
