import React, { FC, memo } from 'react'
import RfhSelect, { RfhSelectProps } from '@rfh/ui/components/Inputs/RfhSelect'
import produce from 'immer'
import { toJS } from 'mobx'
import { ControllerSelectProps } from './types'
import { Department } from '../../services/api/types'

const BrancheSelect: FC<
  Omit<RfhSelectProps, 'items'> & {
    branches: Department[]
    formStepsStore: ControllerSelectProps['formStepsStore']
  }
> = props => {
  const { formStepsStore, defaultValue, branches, ...rest } = props

  return (
    <RfhSelect
      {...rest}
      defaultValue={defaultValue}
      items={branches.map(branch => {
        return {
          id: branch,
          label: branch,
          value: branch,
        }
      })}
      onSelectItemClick={(event, item): void => {
        formStepsStore.setFormData(
          produce(toJS(formStepsStore.formData), draftState => {
            draftState.information.location = item
          })
        )
      }}
    />
  )
}

export default memo(BrancheSelect)
